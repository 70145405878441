import { Component, Input, OnInit } from '@angular/core';
// can import each library as a whole, however all svg files in every imported library would be included in the builds
import {
  IconDefinition,
  faCheck,
  faChevronRight as faSChevronRight,
  faChevronLeft as faSChevronLeft,
  faCoffee,
  faExternalLinkAlt,
  faFile,
  faFolderOpen,
  faImage,
  faLightbulb,
  faLink,
  faPlay,
  faTimes,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import {
  faBolt,
  faBooks,
  faBookOpen,
  faChevronRight,
  faCog,
  faCommentAlt,
  faCommentAltCheck,
  faCopy,
  faFolder,
  faGlobeAmericas,
  faHomeAlt,
  faPenNib,
  faQuestionSquare,
  faSignOutAlt,
  faSpinner,
  faTimesCircle,
  faUser,
} from '@fortawesome/pro-light-svg-icons';
import {
  faSyncAlt,
} from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnInit {
  // https://github.com/FortAwesome/angular-fontawesome/blob/master/docs/usage/features.md
  // list of features available for use
  @Input() iconName: string;
  @Input() fixedWidth: boolean;
  @Input() spin: boolean;
  @Input() pulse: boolean;
  @Input() border: boolean;
  @Input() size: string;
  @Input() pull: string;
  @Input() flip: string;
  @Input() customClass: string;
  iconOptions: IconConfigOptions;

  private defaultIcon = faCoffee; // TODO update default icon
  private availableIcons: object = {
    // CommonName : fontAwesomeIcon
    coffee: faCoffee,
    file: faFile,
    image: faImage,
    link: faLink,
    linkExternal: faExternalLinkAlt,
    hint: faLightbulb,
    watch: faPlay,
    browse: faFolderOpen,
    copy: faCopy,
    kc: faChevronRight,
    loading: faSpinner,
    refresh: faSyncAlt,
    closeControlBar: faTimesCircle,
    tick: faCheck,
    cross: faXmark,

    // Navigation and menu
    home: faHomeAlt,
    back: faSChevronLeft,
    next: faSChevronRight,
    close: faTimes,
    profile: faUser,
    settings: faCog,
    help: faQuestionSquare,
    logout: faSignOutAlt,

    // Apps
    library: faBooks,
    recentBook: faBookOpen,
    author: faPenNib,
    review: faCommentAlt,
    signoff: faCommentAltCheck,
    localise: faGlobeAmericas,
    publish: faBolt,
    files: faFolder,
  };

  ngOnInit() {
    this.loadIcon();
  }

  private loadIcon() {
    const validIcon = this.availableIcons[this.iconName];
    if (!validIcon) {
      // eslint-disable-next-line no-console
      console.error(
        `IconName '${this.iconName}' is not available, please choose one that is or add the required icon to the icon component`
      );
    }

    this.iconOptions = {
      icon: validIcon || this.defaultIcon,
      fixedWidth: this.fixedWidth !== undefined && this.fixedWidth !== false,
      spin: this.spin !== undefined && this.spin !== false,
      pulse: this.pulse !== undefined && this.pulse !== false,
      border: this.border !== undefined && this.border !== false,
      size: this.size,
      pull: this.pull,
      flip: this.flip,
      customClass: this.customClass,
    };
  }
}

// this is just a model - could be moved to separate file if required
interface IconConfigOptions {
  icon: IconDefinition;
  fixedWidth: boolean;
  spin: boolean;
  pulse: boolean;
  border: boolean;
  size: string;
  pull: string;
  flip: string;
  customClass: string;
}
