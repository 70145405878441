import { Injectable } from '@angular/core';
import { FeatureFlagModel } from 'src/app/models/feature-flag-model';
import { ConfigService } from '../config/config.service';

@Injectable({ providedIn: 'root' })
export class FeatureFlagService {
  // Loaded from config file
  private featureFlags: FeatureFlagModel[];

  /**
   * Contruct the feature flag service and populate the enabled features
   * @param config The config service
   */
  constructor(private config: ConfigService) {
    this.featureFlags = this.config.featureFlags;
  }

  /**
   * check if a feature has explicity been enabled
   * @param featureName feature to check the status of
   * @returns true if feature enabled
   */
  featureOn(featureName: string): boolean {
    return (
      this.featureFlags &&
      !!this.featureFlags.find((feature) => {
        return (feature?.name === featureName) && feature?.enabled;
      })
    );
  }

  /**
   * check if a feature is disabled (defaults to disabled if not found)
   * @param featureName feature to check the status of
   * @returns true if feature disabled or not found
   */
  featureOff(featureName: string): boolean {
    return !this.featureOn(featureName);
  }
}
