<fa-icon
    [icon]="iconOptions.icon"
    [fixedWidth]="iconOptions.fixedWidth"
    [spin]="iconOptions.spin"
    [pulse]="iconOptions.pulse"
    [size]="iconOptions.size"
    [border]="iconOptions.border"
    [pull]="iconOptions.pull"
    [flip]="iconOptions.flip"
    [ngClass]="iconOptions.customClass"
></fa-icon>
