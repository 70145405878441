import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { LauncherService } from 'src/app/services/launcher/launcher.service';
import { LoggerService } from 'src/app/services/logger/logger.service';

@Component({
  selector: 'app-files',
  template: '',
})
export class FilesComponent implements OnInit, OnDestroy {
  private keepAliveFiles = null;

  constructor(
    private launcher: LauncherService,
    private route: ActivatedRoute,
    private router: Router,
    private logger: LoggerService,
    private authService: AuthenticationService,
    private configService: ConfigService
  ) { }

  /**
   * Get app name and handle if this data is missing
   * @returns void, navigates to 404 page on missing data
   */
  ngOnInit() {
    const appName: string = this.route?.snapshot?.data?.feature;
    if (!appName) {
      // appName not available on route snapshot data in x component
      this.logger?.error('Encountered an unknown issue loading files');
      this.router?.navigateByUrl(this.router.createUrlTree(['Error/404']));
      return;
    }
    this.launchApp(appName);
  }

  /**
   * Process any custom extras for loading this child route
   * @param appName name of the app to load, taken from the current route
   */
  launchApp(appName: string): void {
    // Keep session alive
    this.keepAliveFiles = setInterval(() => {
      this.authService.refreshToken();
    }, this.configService.default.filesTokenRefreshTime); // Every 4 minutes

    // Build the connection string required by filerun
    const connectionString =
      '?un=' + this.authService.getUserProperty('activeDirectoryUser') +
      '&name=' + this.authService.getUserProperty('name') +
      '&email=' + this.authService.getUserProperty('email') +
      '&org=' + this.authService.getLoadedLibraryAlias() +
      '&guid=' + this.authService.getUserProperty('fileManagerGuid');

    this.launcher.launch(appName, connectionString);
  }

  /**
   * Hide iframes when navigating away
   */
  ngOnDestroy() {
    if (this.keepAliveFiles) {
      clearInterval(this.keepAliveFiles);
      this.keepAliveFiles = null;
    }
    this.launcher?.hideLaunchedApps();
  }
}
