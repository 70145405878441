import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FeatureService } from '../feature/feature.service';
import { LoggerService } from '../logger/logger.service';
import { ResourceService } from '../resource/resource.service';

const portalTitle = 'Author-it';

@Injectable({
  providedIn: 'root',
})
export class BrowserTitleService {
  constructor(
    private titleService: Title,
    private resources: ResourceService,
    private featureService: FeatureService,
    private logger: LoggerService
  ) { }

  /**
   * Add the application title to the browser title, called on Nav End
   * @param url current url string
   */
  updateAppTitle(url: string) {
    const appTitle = this.getAppTitle(url);
    this.setBrowserTitle(appTitle);
  }

  /**
   * Get the currently loaded application title from the current url
   * @param url current url
   * @returns currently loaded application title
   */
  private getAppTitle(url: string): string {
    let appTitle = '';
    if (!!url) {
      const featureTitle = this.featureService.features.find(
        (item) => url?.startsWith(item.route)
      )?.titleRef;
      if (featureTitle) {
        appTitle = this.resources.getString(featureTitle);
      }
    }
    return appTitle;
  }

  /**
   * Set the browser title
   * @param appTitle application title to add to the browser title
   */
  private setBrowserTitle(appTitle: string): void {
    const newBrowserTitle = portalTitle + (appTitle ? ' | ' + appTitle : '');
    if (this.titleService.getTitle() !== newBrowserTitle) {
      this.titleService.setTitle(newBrowserTitle);
      // this.logger.debug('Set browser title to: ' + newBrowserTitle);
    }
  }
}
