import { AfterViewInit, Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from './services/authentication/authentication.service';
import { BrowserTitleService } from './services/browser-title/browser-title.service';
import { ToastService } from './services/toast/toast.service';
import { filter } from 'rxjs/operators';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { AppRouteChangeService } from './services/app-route-change/app-route-change.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  public versionNumber: string;
  public showTestData: boolean;

  constructor(
    private router: Router,
    private browserTitle: BrowserTitleService,
    private authService: AuthenticationService,
    private toastService: ToastService,
    private gtmService: GoogleTagManagerService,
    private routeChangeService: AppRouteChangeService
  ) {
    if (!environment.production) {
      this.showTestData = true;
      this.versionNumber = environment.version;
    }
  }

  ngOnInit() {
    // Watch for and register navigation end events in a service
    // Child components that watch for this dont always get the first nav event on load
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        let destinationUrl = event.url;
        if(!!event?.urlAfterRedirects && event.url !== event.urlAfterRedirects) {
          destinationUrl = event.urlAfterRedirects;
        }
        this.browserTitle.updateAppTitle(destinationUrl);
        this.routeChangeService.navigationEndUrl.next(destinationUrl);
      });
    this.addToGtm();
  }

  ngAfterViewInit() {
    this.toastService.showStoredToastAfterAppLoad();
  }

  showPortal() {
    // authenticated and not navigating to login, select library, or reset password
    return (this.authService.isAuthenticated() && !this.router.url.startsWith('/Login'));
  }

  addToGtm() {
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url,
        };

        this.gtmService.pushTag(gtmTag);
      }
    });
  }
}
