import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { FeatureService } from 'src/app/services/feature/feature.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { UtilService } from 'src/app/services/util/util.service';

@Component({
  selector: 'app-redirect',
  template: '',
})
export class RedirectComponent implements OnDestroy {
  constructor(
    private router: Router,
    private featureService: FeatureService,
    private logger: LoggerService,
    private authService: AuthenticationService,
    private utils: UtilService
  ) {
    this.processRedirect(decodeURIComponent(router.url.substring(10)));
  }

  /**
   * Change route after log in
   */
  private processRedirect(url: string) {
    this.logger.debug('Redirecting to: ' + url || 'Home');

    //#region Exit early if no redirect URL
    if (!url || url.trim() === '' || url === 'null') {
      this.navigateToUrl(this.featureService.getFeatureByFeatureName('home').route);
      return;
    }
    //#endregion

    // if the user entered on a URL and was redirected to login,
    // their original URL is stored in url
    let search = '';
    let index = 0;
    let subRoute = '';

    //#region Are we routing to a book?
    search = '#book/';
    index = url.toLowerCase().indexOf(search);
    if (index >= 0) {
      subRoute = url.substring(index + search.length - 1); // -1 because we need the trailing slash
      this.navigateToUrl(this.featureService.getFeatureByFeatureName('recentbook').route + subRoute);
      return;
    }
    //#endregion

    //#region Are we routing to the library?
    search = '#library';
    index = url.toLowerCase().indexOf(search);
    if (index >= 0) {
      this.navigateToUrl(this.featureService.getFeatureByFeatureName('library').route);
      return;
    }
    //#endregion

    //#region Are we routing to publishing jobs?
    search = '#publishing';
    index = url.toLowerCase().indexOf(search);
    if (index >= 0) {
      subRoute = url.substring(index + search.length);
      this.navigateToUrl(this.featureService.getFeatureByFeatureName('publishing').route + subRoute);
      return;
    }
    //#endregion

    //#region Are we routing to settings?
    search = '#settings';
    index = url.toLowerCase().indexOf(search);
    if (index >= 0) {
      subRoute = url.substring(index + search.length);
      this.navigateToUrl(this.featureService.getFeatureByFeatureName('settings').route + subRoute);
      return;
    }
    //#endregion

    //#region Are we routing to a review?
    // Note that a review URL looks like this: [...]review.aspx#r7954t2209m15900
    // But the redirectUrl looks like this: returnto=[...]review.aspx%3Fhash%3Dr7954t2209m15900
    // Which URL decodes to: returnto=[...]review.aspx?hash=r7954t2209m15900
    // The url can optionally contain &libinfo=xxx
    search = 'review.aspx';
    index = decodeURIComponent(url).toLowerCase().indexOf(search);
    if (index >= 0) {
      // Take the URL after review.aspx
      url = decodeURIComponent(url).substring(index + search.length);
      // If starts with #, replace it with ?hash= so that all URLs are now equivalent
      if (url.startsWith('#')) {
        url = '?hash=' + url.substring(1);
      }
      // Convertand extract the query params
      const queryParams = new URLSearchParams(url);
      if (queryParams.has('hash')) {
        subRoute = '/' + queryParams.get('hash');
        if (queryParams.has('libinfo')) {
          subRoute += '/' + queryParams.get('libinfo');
        }
      }
      this.navigateToUrl(this.featureService.getFeatureByFeatureName('reviews').route + subRoute);
      return;
    }
    //#endregion

    //#region Are we routing to a review approval sign off?
    // Example: [...]signoff.aspx?review=123
    search = 'signoff.aspx';
    index = url.toLowerCase().indexOf(search);
    if (index >= 0) {
      let paramStr = url.substring(index + search.length);
      if (paramStr.startsWith('%')) paramStr = decodeURIComponent(paramStr);
      const queryParams = new URLSearchParams(paramStr);
      if (queryParams.has('review')) {
        subRoute = '/' + queryParams.get('review');
        if (queryParams.has('libinfo')) {
          subRoute += '/' + queryParams.get('libinfo');
        }
      }
      this.navigateToUrl(this.featureService.getFeatureByFeatureName('signoff').route + subRoute);
      return;
    }
    //#endregion

    //#region Are we routing to a translation?
    // TODO: Cannot find any direct links to jobs within Localize. Does it need to be supported?
    search = 'localize.aspx';
    index = url.toLowerCase().indexOf(search);
    if (index >= 0) {
      this.navigateToUrl(this.featureService.getFeatureByFeatureName('translations').route);
      return;
    }
    //#endregion

    //#region Routing to a publishing output, reload original URL in browser window
    // Example: Login?returnUrl=contribute%2Fpublishing%2F[...];
    search = 'login?returnurl=/contribute/publishing/';
    if (!!decodeURIComponent(url).toLowerCase().startsWith(search)) {
      url = decodeURIComponent(url).substring(16);

      const finalUrl = this.utils.window().location.protocol + '//' + this.utils.window().location.hostname + url;
      this.logger.debug('Redirect to: ' + finalUrl);

      this.utils.window().location.href = finalUrl;
      return;
    }
    //#endregion

    // If all else fails, redirect to the original request URL
    this.navigateToUrl(url);
  }

  /**
   * Perform navigation to specified url, replaces current url (/Redirect/) in location history with specified url
   * @param url router url to navigate to
   */
  private navigateToUrl(url: string) {
    // navigates to url, and replaces /Redirect/ in location history with final url
    this.router.navigate([url], {replaceUrl: true});
  }

  /**
   * clear redirect url after navigation
   */
  ngOnDestroy(): void {
    this.authService.clearRedirectUrl();
  }
}
