import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FeatureService } from 'src/app/services/feature/feature.service';
import { LauncherService } from 'src/app/services/launcher/launcher.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { RecentBookService } from 'src/app/services/recent-book/recent-book.service';

@Component({
  selector: 'app-recent-book',
  template: '',
})
export class RecentBookComponent implements OnInit, OnDestroy {
  constructor(
    private launcher: LauncherService,
    private route: ActivatedRoute,
    private router: Router,
    private logger: LoggerService,
    private featureService: FeatureService,
    private recentBookService: RecentBookService,
    private location: Location
  ) { }

  /**
   * Get app name and handle if this data is missing
   * @returns void, navigates to 404 page on missing data
   */
  ngOnInit() {
    const appName: string = this.route?.snapshot?.data?.feature;
    if (!appName) {
      // appName not available on route snapshot data in x component
      this.logger?.error('Encountered an unknown issue loading book');
      this.router?.navigateByUrl(this.router.createUrlTree(['Error/404']));
      return;
    }
    this.launchApp(appName);
  }

  /**
   * Process any custom extras for loading this child route
   * @param appName name of the app to load, taken from the current route
   */
  async launchApp(appName: string): Promise<void> {
    let bookId = this.route?.snapshot?.params?.id;
    let bookName = this.route?.snapshot?.queryParams?.name;
    let urlChanges: boolean = false;

    // no book id provided in route
    if (!bookId) {
      const recentBook = this.recentBookService.getRecentBook();

      if (!recentBook?.bookId) {
        // no recently accessed book
        this.logger.debug('No recent book found, redirecting to library list');
        this.router.navigateByUrl(this.router.createUrlTree(['Library']));
        return;
      }
      bookId = recentBook.bookId;
      bookName = recentBook.bookName;
      urlChanges = true;
    }

    // no bookName provided in route
    if (!bookName) {
      bookName = await this.recentBookService.getBookTitle(bookId);
      if (!!bookName) {
        urlChanges = true;
      }
    }
    // always store as is
    this.recentBookService.storeRecentBook(bookId, bookName);
    // always set title as is - if no bookName, will show default
    this.featureService.addRecentBook(bookName);

    let subRoute = '/' + bookId;
    if (!!bookName) {
      subRoute += '?name=' + encodeURIComponent(bookName);
    }

    if (urlChanges) {
      const feature = this.featureService.getFeatureByFeatureName(appName);
      this.location.replaceState(feature.route + subRoute);
    }

    this.featureService.updateRouteParams(appName, '/' + bookId, (bookName ? { name: bookName } : ''));
    this.launcher.launch(appName, subRoute);
  }

  /**
   * Hide iframes when navigating away
   */
  ngOnDestroy() {
    this.launcher?.hideLaunchedApps();
  }
}