import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppRouteChangeService {
  /**
   * Subject holding final navigation url, from NavigationEnd event
   */
  public navigationEndUrl: BehaviorSubject<string> = new BehaviorSubject<string>('/');

}
