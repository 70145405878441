import {
  Component,
  HostListener,
  ViewChild,
  AfterViewChecked,
  OnInit,
  Input,
} from '@angular/core';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { DialogSettingsModel } from 'src/app/models/dialog-settings.model';
import { ConfigService } from 'src/app/services/config/config.service';
import { LoggerService } from 'src/app/services/logger/logger.service';

@Component({
  selector: 'app-shared-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class SharedDialogComponent implements OnInit, AfterViewChecked {
  @Input() customSettings: DialogSettingsModel;
  @Input() content: any; //HTMLElement | string;
  @ViewChild('dialog') dialog: DialogComponent;

  public settings: DialogSettingsModel = {
    id: 'dialog',
    width: '500px',
    height: 'auto',
    minHeight: '',
    isModal: true,
    visible: false,
    buttons: [{}],
    showCloseIcon: false, //defaults to false
    target: null, //Specifies the target element in which to display the dialog. The default value is null, which refers the document.body element.
    cssClass: 'dark',
    animationSettings: {duration: 0}, // Populated from config
  };

  public constructor(private configService: ConfigService, private logger: LoggerService) {
    this.settings.animationSettings.duration = this.configService.default.dialogAnimationDuration;
  }

  ngOnInit(): void {
    if (this.customSettings) {
      // apply any custom settings here
      Object.assign(this.settings, this.customSettings);
    }
  }

  ngAfterViewChecked(): void {
    this.refreshPosition();
  }

  /**
   * Ensure that the dialog remains centered when the window resizes
   */
  @HostListener('window:resize')
  autoRefreshPosition() {
    this.refreshPosition();
  }

  public refreshPosition() {
    try {
      if (this.dialog && this.dialog.visible) {
        this.dialog.refreshPosition();
      }
    } catch {
      // throws errors when the SF component is not quite ready yet, just ignore
      // this.logger.debug('Internal component error while refreshing dialog position');
    }
  }

  hide() {
    this.dialog.hide();
  }

  show() {
    this.dialog.show();
  }
}
