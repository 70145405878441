import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIResponse } from 'src/app/models/api-response.model';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(
    private configService: ConfigService,
    private httpClient: HttpClient
  ) {}

  /**
   * Wrapper for httpClient GET that handles some of the work for us. This method automatically
   * builds the correct URL, adds the authentication token, and formats the response on error
   * @param url The relative URL for the call. e.g. v1/book/123
   */
  async get(url: string): Promise<APIResponse> {
    try {
      return await this.httpClient.get<APIResponse>(this.configService.aitApiEndpoint + url).toPromise();
    } catch (e) {
      return {
        Success: false,
        Reason: 'couldNotCommunicateWithApiError',
        Data: {
          Message: e.message,
        },
      };
    }
  }

  /**
   * Wrapper for httpClient POST that handles some of the work for us. This method builds the correct URL, and formats the response on error
   * @param url The relative URL for the call. e.g. v1/book/
   * @param body An object containing the data to send in the post body, if null {} is used instead
   */
  async post(
    url: string,
    body?: any
  ): Promise<APIResponse> {
    try {
      return await this.httpClient
        .post<APIResponse>(
          this.configService.aitApiEndpoint + url,
          body ?? {}
        )
        .toPromise();
    } catch (e) {
      return {
        Success: false,
        Reason: 'couldNotCommunicateWithApiError',
        Data: {
          Message: e.message,
        },
      };
    }
  }

}
