import { Injectable } from '@angular/core';
import { LauncherComponent } from 'src/app/components/launcher/launcher.component';

/**
 * This service provides a connection between the launching application, and the iframe launcher/manager component
 */
@Injectable({
  providedIn: 'root',
})
export class LauncherService {
  private launcherRef: LauncherComponent;

  /**
   * Attach the launcher to the service
   * @param launcher
   */
  public attach(launcher: LauncherComponent) {
    this.launcherRef = launcher;
  }

  /**
   * Launch an app in an iframe
   * @param appName The name of the app to launch
   * @param subRoute extra url details to concat onto the base url of a child app
   */
  public launch(appName: string, subRoute?: string): void {
    this.launcherRef.launchApp(appName, subRoute);
  }

  /**
   * Hide all previously loaded iframes
   */
  public hideLaunchedApps() {
    this.launcherRef?.hideLaunchedApps();
  }

  /**
   * Reload an app
   * @param appName The name of the app to reload
   */
  public reloadApp(appName: string) {
    this.launcherRef?.reloadApp(appName);
  }

  /**
   * Close an app
   * @param appName The name of the app to close
   */
  public closeApp(appName: string) {
    this.launcherRef?.closeApp(appName);
  }

  /**
   * Check whether a specific iframe exists
   * @param appName The name of the app/iframe to check
   * @returns True if exists, false otherwise
   */
  public appHasLaunched(appName: string) {
    return this.launcherRef?.appHasLaunched(appName);
  }
}
