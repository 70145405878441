/**
 * Model for data that is sent to the spark app
 */
export class SparkData {
  domain: string;
  gateway: string;
  server: string;
  locale: string;
  keyboard: string;
  sparkLoading: string;
  sparkConnecting: string;
  sparkAuthenticating: string;
  sparkStarting: string;
}
