import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppRouteChangeService } from 'src/app/services/app-route-change/app-route-change.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { FeatureService } from 'src/app/services/feature/feature.service';
import { ResourceService } from 'src/app/services/resource/resource.service';
import { FeatureItemModel } from 'src/app/models/feature-item.model';
import { LauncherService } from 'src/app/services/launcher/launcher.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';

/**
 * Show Something while waiting for the application to load
 */
const defaultHeader = [{ title: 'loadingLabel' }]; // Pulled from translations file

type headerPart = {
  /**
   * Title to show, titleRef to be translated, or featureName for direct string display
   */
  title: string;
  /**
   * Do not translate this
   */
  exact?: boolean;
  /**
   * Pull direct from featureService
   */
  direct?: boolean;
};

@Component({
  selector: 'app-control-bar',
  templateUrl: './control-bar.component.html',
  styleUrls: ['./control-bar.component.scss'],
})
export class ControlBarComponent implements OnInit, OnDestroy {
  private navEndUrlSubscription: Subscription;
  public activeFeature: FeatureItemModel = null;
  public headerParts: headerPart[] = defaultHeader;
  public hideControlBar: boolean = false;
  public iconRefesh: string = 'refresh';
  public iconClose: string = 'close';

  constructor(
    public featureService: FeatureService,
    private authService: AuthenticationService,
    public resources: ResourceService,
    private routeChangeService: AppRouteChangeService,
    private launcherService: LauncherService,
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {
    this.navEndUrlSubscription = this.routeChangeService.navigationEndUrl.subscribe((url) => {
      this.updateHeader(url);
    });
  }

  ngOnDestroy() {
    this.navEndUrlSubscription.unsubscribe();
  }

  updateHeader(url: string): void {
    // match route from feature
    this.activeFeature = this.featureService.features.find(
      (item) => url?.startsWith(item.route)
    );
    if (!this.activeFeature) {
      this.headerParts = defaultHeader;
      this.hideControlBar = true;
      return;
    }

    this.hideControlBar = !this.activeFeature.showControlBar;

    switch (this.activeFeature.featureName) {
    case this.featureService?.getFeatureByFeatureName('recentbook')?.featureName:
      // retrieving the book name here from the book service or the feature gets it too early so is not updated correctly
      this.changeHeaderText([{ title: this.activeFeature.titleRef }, { title: this.activeFeature.featureName, direct: true }]);
      break;

    case this.featureService?.getFeatureByFeatureName('library')?.featureName:
      this.changeHeaderText([{ title: this.activeFeature.titleRef }, { title: this.authService.getLoadedLibrary()?.name, exact: true }]);
      break;

    default:
      this.changeHeaderText([{ title: this.activeFeature.titleRef }]);
      break;
    }
  }

  /**
   * Update header array with new array
   * Any custom filtering etc to be done can happen here in future
   * @param parts array of header items to display
   */
  changeHeaderText(parts: headerPart[]) {
    if (!(parts?.length > 0)) {
      // nothing provided, change text to loading
      this.headerParts = defaultHeader;
      return;
    }
    // remove any parts that have an undefined title
    this.headerParts = parts.filter((p) => !!p.title);
  }

  // refresh iframe
  onReloadClick() {
    // Show confirmation dialog
    this.dialogService.showDialog({
      title: this.resources.localisedStrings.reloadConfirmTitle,
      message: this.resources.localisedStrings.reloadConfirmDescription,
      yesButton: { label: this.resources.localisedStrings.reload, click: () => this.reloadApp() },
      noButton: { label: this.resources.localisedStrings.cancel },
    });
  }

  public reloadApp() {
    // Close dialog
    this.dialogService.hideDialog();
    // Reload app
    this.launcherService.reloadApp(this.activeFeature.featureName);
  }

  onCloseClick() {
    const title = ' ' + this.resources.localisedStrings[this.headerParts[0].title];
    // Show confirmation dialog
    this.dialogService.showDialog({
      title: this.resources.localisedStrings.closeConfirmTitle + title,
      message: this.resources.localisedStrings.closeConfirmDescription,
      yesButton: { label: this.resources.localisedStrings.confirm, click: () => this.closeApp() },
      noButton: { label: this.resources.localisedStrings.cancel },
    });
  }

  public closeApp() {
    // Close dialog
    this.dialogService.hideDialog();
    // Close app
    this.launcherService.closeApp(this.activeFeature.featureName);
  }
}
