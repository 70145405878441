import { environment } from 'src/environments/environment';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SidebarModule } from '@syncfusion/ej2-angular-navigations';
import { ButtonModule, CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { ToastModule } from '@syncfusion/ej2-angular-notifications';

import { CardModule } from 'primeng/card';

import { AppComponent } from './app.component';
import { ConfigService } from './services/config/config.service';
import { ResourceService } from './services/resource/resource.service';
import { SidePanelComponent } from './components/side-panel/side-panel.component';
import { LauncherComponent } from './components/launcher/launcher.component';
import { ErrorComponent } from './components/error/error.component';
import { IconComponent } from './components/icon/icon.component';
import { RemoveIfFeatureOffDirective } from './services/feature-flags/remove-if-feature-off.directive';
import { FeatureFlagService } from './services/feature-flags/feature-flag.service';
import { AuthenticationService } from './services/authentication/authentication.service';
import { MessengerService } from './services/messenger/messenger.service';
import { ReviewsComponent } from './components/launcher/app-launcher/reviews/reviews.component';
import { SignOffComponent } from './components/launcher/app-launcher/signoff/signoff.component';
import { RecentBookComponent } from './components/launcher/app-launcher/recent-book/recent-book.component';
import { PublishingComponent } from './components/launcher/app-launcher/publishing/publishing.component';
import { TranslationsComponent } from './components/launcher/app-launcher/translations/translations.component';
import { FilesComponent } from './components/launcher/app-launcher/files/files.component';
import { AuthorComponent } from './components/launcher/app-launcher/author/author.component';
import { AuthorDialogComponent } from './components/dialogs/author-dialog/author-dialog.component';
import { SettingsComponent } from './components/launcher/app-launcher/settings/settings.component';
import { ProfileComponent } from './components/launcher/app-launcher/profile/profile.component';
import { LibraryComponent } from './components/launcher/app-launcher/library/library.component';
import { LoginComponent } from './components/login/login.component';
import { LauncherService } from './services/launcher/launcher.service';
import { HomeComponent } from './components/home/home.component';
import { HomePageService } from './services/home-page/home-page.service';
import { CookieService } from './services/cookie/cookie.service';
import { AppErrorHandler } from './services/app-error-handler/app-error-handler.service';
import { ToastService } from './services/toast/toast.service';
import { AutologoutDialogComponent } from './components/dialogs/autologout-dialog/autologout-dialog.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { LoggerService } from './services/logger/logger.service';
import { ControlBarComponent } from './components/control-bar/control-bar.component';
import { UserflowService } from './services/userflow/userflow.service';
import { FeatureService } from './services/feature/feature.service';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { AppRouteChangeService } from './services/app-route-change/app-route-change.service';
import { SpinnerService } from './services/spinner/spinner.service';
import { DialogService } from './services/dialog/dialog.service';
import { UtilService } from './services/util/util.service';
import { SharedDialogComponent } from './components/shared/dialog/dialog.component';
import { CompleteReviewDialogComponent } from './components/dialogs/complete-review-dialog/complete-review-dialog.component';

const appInitializer = (
  config: ConfigService,
  resource: ResourceService,
  authentication: AuthenticationService
) => {
  return async () => {
    await config.loadConfig();
    await resource.loadResources();
    await authentication.init();
    return;
  };
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SidePanelComponent,
    LauncherComponent,
    AuthorDialogComponent,
    IconComponent,
    RemoveIfFeatureOffDirective,
    ErrorComponent,
    LibraryComponent,
    ReviewsComponent,
    SignOffComponent,
    RecentBookComponent,
    PublishingComponent,
    TranslationsComponent,
    FilesComponent,
    AuthorComponent,
    SettingsComponent,
    ProfileComponent,
    HomeComponent,
    AutologoutDialogComponent,
    RedirectComponent,
    ControlBarComponent,
    SharedDialogComponent,
    CompleteReviewDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FontAwesomeModule,
    // Syncfusion components
    SidebarModule,
    ButtonModule,
    CheckBoxModule,
    TextBoxModule,
    DialogModule,
    DropDownListModule,
    ToastModule,
    // PrimeNG components
    CardModule,

    GoogleTagManagerModule.forRoot({
      id: environment.gtmToken,
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [ConfigService, ResourceService, AuthenticationService],
    },
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler,
    },
    AppRouteChangeService,
    FeatureFlagService,
    LauncherService,
    ToastService,
    DialogService,
    ResourceService,
    { provide: 'whoAmI', useValue: 'portal' },
    AuthenticationService,
    MessengerService,
    HomePageService,
    CookieService,
    LoggerService,
    UserflowService,
    FeatureService,
    SpinnerService,
    UtilService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
