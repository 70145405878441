import { Injectable } from '@angular/core';
import { ButtonArgs, ConfirmDialogArgs, Dialog, DialogUtility, PositionDataModel } from '@syncfusion/ej2-popups';

type DialogPropsButtonCallback = () => void;
interface DialogPropsButton {
  click?: DialogPropsButtonCallback;
  label?: string;
}
interface DialogProps {
  title: string;
  message: string;
  yesButton?: DialogPropsButton;
  noButton?: DialogPropsButton;
  position?: PositionDataModel;
  cssClass?: string;
}

// Full info https://www.syncfusion.com/kb/10340/how-to-use-toast-as-an-angular-service
@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private dialogInstance: Dialog;
  private dialogProps: DialogProps;

  /**
   * Show a dialog message to the user
   * @param title Toast title
   * @param message Toast message
   */
  public showDialog(properties: DialogProps) {
    this.dialogProps = properties;
    const props : ConfirmDialogArgs = {
      cssClass: properties.cssClass || 'dark',
      title: properties.title,
      content: properties.message,
      showCloseIcon: true,
      closeOnEscape: true,
    };
    // Has yes button?
    if (!!properties.yesButton) {
      const btn : ButtonArgs = {
        text: properties.yesButton.label,
        click: this.yesClick.bind(this),
      };
      props.okButton = btn;
    }
    // Has no button?
    if (!!properties.noButton) {
      const btn : ButtonArgs = {
        text: properties.noButton.label,
        click: this.noClick.bind(this),
      };
      props.cancelButton = btn;
    }
    if(!!properties.position) {
      props.position = properties.position;
    }
    // Show dialog
    this.dialogInstance = DialogUtility.confirm(props);
  }

  /**
   * Hide dialog
   */
  public hideDialog() {
    if (this.dialogInstance) {
      this.dialogInstance.hide();
      this.dialogInstance = null;
    }
  }

  /**
   * Yes button has been clicked
   */
  private yesClick() {
    this.hideDialog();
    if (!!this.dialogProps?.yesButton?.click) {
      this.dialogProps.yesButton.click();
    }
  }

  /**
   * No button has been clicked
   */
  private noClick() {
    this.hideDialog();
    if (!!this.dialogProps?.noButton?.click) {
      this.dialogProps.noButton.click();
    }
  }
}
