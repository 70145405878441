import {
  Component,
  ViewChild,
  HostListener,
  AfterViewChecked,
  AfterContentChecked,
  OnInit,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { LoginValidationMessage } from 'src/app/models/login-validation-message.model';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { ResourceService } from 'src/app/services/resource/resource.service';
import { UtilService } from 'src/app/services/util/util.service';

/**
 * The possible steps in the login process
 */
enum LoginStep {
  Login = 1, // Enter email address and password and validate
  SelectLibrary = 2, // Select library and log in/authenticate
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewChecked, AfterContentChecked {
  //#region class variables

  /**
   * Reference to the dialog for repositioning on screen resize
   */
  @ViewChild('loginDialog') dialog: DialogComponent;

  /**
   * The current step in the login process
   * @see LoginStep
   */
  step: LoginStep = LoginStep.Login;

  dialogWidth = '500px';
  dialogHeight = 'auto';

  /**
   * List of current messages
   */
  messages: LoginValidationMessage[] = [];


  /**
   * Input fields
   */
  email = new UntypedFormControl('', [Validators.required, Validators.email]);
  password = new UntypedFormControl('', [Validators.required]);
  library = new UntypedFormControl('');
  loginForm = new UntypedFormGroup({
    email: this.email,
    password: this.password,
    library: this.library,
  });

  /**
   * Libraries come from API call
   */
  libraryData = [];
  libraryDropdownInitiated = false;
  /**
   * Mapping of library data to fields for the dropdown component
   */
  public libraryDataFields: object = { text: 'name', value: 'id' };

  loading = false;

  //#endregion class variables

  constructor(
    public authService: AuthenticationService,
    public resources: ResourceService,
    private logger: LoggerService,
    private router: Router,
    private utils: UtilService,
    public configService: ConfigService
  ) { }

  ngOnInit() {
    this.redirectIfInIframe();

    if (this.router.url.toLowerCase().includes('/selectlibrary')) {
      // Populate library list
      this.libraryData = this.authService.getLibraries();
      this.stepChanged(LoginStep.SelectLibrary);
    }
    if (this.authService.passwordUpdated) {
      this.messages.push({
        text: this.resources.localisedStrings.passwordResetSuccessMessage,
        type: 'info',
      });
      this.authService.passwordUpdated = false;
    }
  }

  /**
   * Ensure that the login dialog remains centered when the window resizes
   * @param event The resize event
   */
  @HostListener('window:resize', ['$event'])
  resizeEventHandler() {
    this.updateDialog();
  }

  /**
   * After Angular updates the view, ensure that the dialog position is updated to stay centered
   */
  ngAfterViewChecked() {
    this.updateDialog();
  }

  /**
   * After angular checks the content, update the library dropdown (if required).
   * Processing this code during ngAfterViewChecked was throwing an error within the syncfusion component. Google said this was the answer :)
   */
  ngAfterContentChecked() {
    // See if we need to select default library
    if (this.step === LoginStep.SelectLibrary && !this.libraryDropdownInitiated) {
      this.libraryDropdownInitiated = true;
      this.libraryData.forEach((element) => {
        if (element.default) {
          if (this.library.value !== element.id) {
            this.library.setValue(element.id);
            this.library.markAsDirty();
          }
        }
      });
    }
  }

  private updateDialog() {
    try {
      if (this.dialog) this.dialog.refreshPosition();
    } catch (e) {
      // throws errors when the SF component is not quite ready yet, just ignore
      // this.logger.debug('Internal component error while refreshing dialog position');
    }
  }

  private setLoading(pending: boolean) {
    if(pending) {
      this.loginForm.disable();
      this.loading = true;
    } else {
      this.loginForm.enable();
      this.loading = false;
    }
  }

  /**
   * Check if we are in an iframe, if we are change the top level portal to the login screen to prevent portal-ception
   * @returns true if in iframe
   */
  redirectIfInIframe() {
    // Are we in an iframe?
    let inIframe = false;
    try {
      inIframe = this.utils.window().self !== this.utils.window().top;
    } catch (e) {
      // Old versions of IE can throw error
      inIframe = false;
    }
    if (inIframe) {
      this.logger.debug('navigate window.top to login');
      // navigate top level application to login
      this.utils.window().top.location.href = this.utils.window().location.origin + '/Login';
    }
  }

  /**
   * Check the current login step in order to display the correct form elements
   * @param step The step to check
   * @returns
   */
  isLoginStep(step: number) {
    return this.step === step;
  }

  /**
   * Step change has been validated. We now do it.
   * @param nextStep The step to change to
   */
  private stepChanged(nextStep) {
    // Change step
    this.step = nextStep;
    this.setLoading(false);
  }

  /**
   * Validate fields on Login
   */
  private validateLoginFields() {
    this.messages = [];
    // Validate email address
    if (!this.email.valid) {
      this.messages.push({
        text: this.resources.localisedStrings.enterValidEmailMessage,
        type: 'error',
        controlName: 'email',
      });
    }
    // Validate password
    if (!this.password.valid) {
      this.messages.push({
        text: this.resources.localisedStrings.enterPasswordMessage,
        type: 'error',
        controlName: 'password',
      });
    }
  }

  /**
   * User has entered their email address and password, and clicked continue.
   * Try authenticate the user with the API
   * If the user has more than one library a list of libraries is returned, and the user is shown the SelectLibrary step
   */
  async login() {
    this.validateLoginFields();
    // If there were validation errors, return
    if (this.messages.length > 0) {
      this.updateDialog();
      return;
    }

    // TODO: Show loading spinner
    this.setLoading(true);

    this.libraryDropdownInitiated = false;

    // Load user
    const response = await this.authService.login(
      this.email.value,
      this.password.value
    );

    // TODO: Hide loading spinner
    this.setLoading(false);

    // Handle response. If the user has access to only one library, authenticate immedaitely.
    // If they have access to more than one, show the library selector.
    if (response.success) {
      // Check if we are fully authenticated
      if (this.authService.isAuthenticated()) {
        // No need to handle success. This is handled in app.component
        return;
      }
      // No libraries. Maybe new user?
      if (this.authService.getLibraries().length === 0) {
        // No libraries. Error
        this.messages.push({
          text: this.resources.localisedStrings.unknownLoginError,
          type: 'error',
        });
        return;
      }
      // Only a single library, so just authenticate with it
      else if (this.authService.getLibraries().length === 1) {
        this.loginWithLibrary();
        return;
      }

      // Populate library list, and set default library to their last used library
      this.libraryData = this.authService.getLibraries();
      // Change the form
      this.stepChanged(LoginStep.SelectLibrary);
      this.router.navigate(['/Login/SelectLibrary']);
    } else {
      // Show error message
      this.messages.push({
        text: response.message,
        type: 'error',
      });
      this.stepChanged(LoginStep.Login);
    }
  }

  /**
   * User has clicked to login
   * Should have valid password, and a selected library if they have access to more than one.
   */
  async loginWithLibrary() {
    this.messages = [];
    // User is valid

    // TODO: Show spinner
    this.setLoading(true);

    // Get selected library
    const selectedLibrary = this.library.value;

    // If the user is already authenticated we just select a new library here
    let response;
    if (!!this.authService.isAuthenticated()) {

      // Check the token to see if session has expired in the mean time
      const active = await this.authService.checkToken();
      if (!active) {
        // SSO
        if (!!this.authService.ssoDomain) {
          this.authService.navToSSOLogin('&expired=1');
          this.setLoading(false);
          return;
        }
        // Non-SSO
        else {
          // Clear token so that isAuthenticated returns false
          this.authService.secureToken = null;
          // Return to login page with error message
          this.messages.push({
            text: this.resources.localisedStrings.sessionExpiredDescription,
            type: 'error'
          });
          this.stepChanged(LoginStep.Login);
          this.router.navigate(['/Login']);
          return;
        }
      }
      // Select the library
      response = await this.authService.selectLibrary(selectedLibrary);
    }

    // If the user is not authenticated, log in with library
    else {
      response = await this.authService.loginWithLibrary(selectedLibrary);
    }

    // Handle response
    if (!response.success) {
      // Show error message
      this.messages.push({
        text: response.message,
        type: 'error',
      });
      this.stepChanged(LoginStep.SelectLibrary);
      // No need to handle success. The above calls to authService.loginWithLibrary/selectLibrary
      // eventually route to /Redirect and direct the user to the original URL they intended to go to
    }
    this.setLoading(false);
  }

  /**
   * User has clicked on forgot password
   * Check that the user has entered a valid email address and then fire off the process
   */
  forgotPassword() {
    // Ignore if the form is disabled (this means that we are waiting for an API response)
    if (this.loginForm.disabled) return;

    this.messages = [];

    if (!this.email.valid) {
      this.messages.push({
        text: this.resources.localisedStrings.enterEmailAndClickForgotPasswordMessage,
        type: 'error',
      });
      return;
    }

    // Kick off the forgot password process
    this.authService.forgotPassword(this.email.value);
    this.messages.push({
      text: this.resources.localisedStrings.youWillReceiveAnEmailWithInstructionsMessage,
      type: 'info',
    });
  }

  /**
   * Change locale for the application
   * @param newLocale locale to change to
   */
  changeLocale(newLocale) {
    this.resources.changeLocale(newLocale);
    this.messages = [];
  }

  /**
   * Called when a message is clicked. Will focus on the relevent UI element
   * @param controlName The name of the control that the message relates to
   */
  messageClick(controlName: string) {
    if (!controlName) return;
    const elems = document.getElementsByName(controlName);
    if (elems.length) {
      elems[0].focus();
    }
  }

  /**
   * Return to the first login screen
   */
  backToLogin() {
    this.messages = [];
    this.stepChanged(LoginStep.Login);
    this.router.navigate(['/Login']);
  }
}
