import { ErrorHandler, Injectable, Injector, Renderer2 } from '@angular/core';
import { LoggerService } from '../logger/logger.service';
import { ResourceService } from '../resource/resource.service';

@Injectable({
  providedIn: 'root',
})
export class AppErrorHandler extends ErrorHandler {
  private resources: ResourceService;
  private logger: LoggerService;

  constructor(private injector: Injector) {
    super();
  }

  /**
   * Initialise required properties for the ErrorHandlerService
   */
  private initServiceProperties(): void {
    if (!this.resources) {
      this.resources = this.injector.get(ResourceService);
    }
    if (!this.logger) {
      this.logger = this.injector.get(LoggerService);
    }
  }

  /**
   * Handle errors thrown by the application
   * @param error Thrown error object, include customer facing message explaining what went wrong
   */
  public handleError(error: ErrorEvent): void {
    try {
      this.initServiceProperties();
      this.logger.error(error.message);
    } catch (e) {
      // Error trying to log message, fallback to default error handler
      super.handleError(error);
    } finally {
      // TODO Always log to somewhere
    }
  }
}
