import { Injectable } from '@angular/core';
import dateFormat from 'dateformat';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  /**
   * Get the window object, wrapper function added to enable mocking
   * @returns window object as it is
   */
  public window(): Window & typeof globalThis {
    return window;
  }

  /**
   * Check if we are in an iframe, if we are change the top level portal to the login screen to prevent portal-ception
   * @returns true if in iframe
   */
  public inIframe() {
    // Are we in an iframe?
    let inIframe = false;
    try {
      inIframe = this.window().self !== this.window().top;
    } catch (e) {
      // Old versions of IE can throw error
      inIframe = false;
    }
    return inIframe;
  }

  /**
   * Navigate the top level window to the login screen
   */
  public navTopLevelWindowToLogin() {
    this.window().top.location.href = this.window().location.origin + '/Login';
  }

  /**
   * Change object keys from PascalCase to camelCase, for a single object or array of objects
   * @param o object to convert keys
   * @returns converted object
   */
  toCamel(o) {
    var newO, origKey, newKey, value;
    if (o instanceof Array) {
      return o.map((value) => {
        if (typeof value === "object") {
          value = this.toCamel(value);
        }
        return value;
      });
    } else {
      newO = {};
      for (origKey in o) {
        if (o.hasOwnProperty(origKey)) {
          newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString();
          value = o[origKey];
          if (value instanceof Array || (value !== null && value.constructor === Object)) {
            value = this.toCamel(value);
          }
          newO[newKey] = value;
        }
      }
    }
    return newO;
  }

  /**
   * Encode as base-64 string
   * @param key string to encode
   * @returns base-64 encoded string
   */
  public base64Encode(key: string): string {
    // Any data stored in client side is considered not secure
    // just obfuscating with base-64 encoding to provide a basic layer of abstraction from the raw data
    return this.window().btoa(key);
  }

  /**
   * Decode from base-64 string
   * @param key base-64 string to decode
   * @returns decoded string
   */
  public base64Decode(key: string): string {
    // Any data stored in client side is considered not secure
    // just obfuscating with base-64 encoding to provide a basic layer of abstraction from the raw data
    return this.window().atob(key);
  }

  /**
   * Replaces one or more format items in a string with the string representation of a specified object.
   * @param format A composite format string
   * @param arg0 he object to format
   * @returns A copy of format in which any format items are replaced by the string representation of arg0.
   */
  public formatString(format: string, args: any): string {
    // Any data stored in client side is considered not secure
    // just obfuscating with base-64 encoding to provide a basic layer of abstraction from the raw data
    // var args = arguments;
    if (Array.isArray(args)) {
      return format.replace(/{(\d+)}/g, function(match, number) {
        return typeof args[number] != 'undefined'
          ? args[number]
          : match
        ;
      });
    } else {
      var keys = Object.keys(args);
      if(!(keys?.length > 0)) {
        return format;
      }
      keys.forEach((key) => {
        format = format.replace(`{${key}}`, args[key]);
      });
      return format;
    }
  }

  /**
   * Format a date as a string
   * @param date date to format
   * @param format format to use. See https://www.npmjs.com/package/dateformat for format options
   * @param asUtc if true, assume date is local time and convert it to UTC time first
   * @returns formatted date
   */
  public formatDate(date: Date, format: string, asUtc: boolean = true): string {
    const utcPrefix = asUtc ? 'UTC:' : '';
    return dateFormat(date, utcPrefix + format);
  }
}
