import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { LauncherService } from 'src/app/services/launcher/launcher.service';
import { LoggerService } from 'src/app/services/logger/logger.service';

@Component({
  selector: 'app-author',
  template: '',
})
export class AuthorComponent implements OnInit, OnDestroy {
  private keepAliveInterval = null;

  constructor(
    private launcher: LauncherService,
    private route: ActivatedRoute,
    private router: Router,
    private logger: LoggerService,
    private authService: AuthenticationService,
    private configService: ConfigService
  ) { }

  /**
   * Get app name and handle if this data is missing
   * @returns void, navigates to 404 page on missing data
   */
  ngOnInit() {
    const appName: string = this.route?.snapshot?.data?.feature;
    if (!appName) {
      // appName not available on route snapshot data in x component
      this.logger?.error('Encountered an unknown issue loading author');
      this.router?.navigateByUrl(this.router.createUrlTree(['Error/404']));
      return;
    }
    this.launchApp(appName);
  }

  launchApp(appName) {
    // Keep session alive
    this.keepAliveInterval = setInterval(() => {
      this.authService.refreshToken();
    }, this.configService.default.authorTokenRefreshTime); // Every 4 minutes
    this.launcher.launch(appName);
  }

  ngOnDestroy() {
    if (this.keepAliveInterval) {
      clearInterval(this.keepAliveInterval);
      this.keepAliveInterval = null;
    }
    this.launcher?.hideLaunchedApps();
  }
}
