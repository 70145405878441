import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LauncherService } from 'src/app/services/launcher/launcher.service';
import { LoggerService } from 'src/app/services/logger/logger.service';

@Component({
  selector: 'app-translations',
  template: '',
})
export class TranslationsComponent implements OnInit, OnDestroy {
  constructor(
    private launcher: LauncherService,
    private route: ActivatedRoute,
    private router: Router,
    private logger: LoggerService
  ) { }

  /**
   * Get app name and pass to launcher to process
   * @returns void, navigates to 404 page on missing data
   */
  ngOnInit() {
    const appName: string = this.route?.snapshot?.data?.feature;
    if (!appName) {
      // appName not available on route snapshot data in x component
      this.logger?.error('Encountered an unknown issue loading translations');
      this.router?.navigateByUrl(this.router.createUrlTree(['Error/404']));
      return;
    }
    this.launcher.launch(appName);
  }

  /**
   * Hide iframes when navigating away
   */
  ngOnDestroy() {
    this.launcher?.hideLaunchedApps();
  }
}
