import { Injectable } from '@angular/core';
import { LibraryModel } from 'src/app/models/library.model';
import { User } from 'src/app/models/user.model';
import { ConfigService } from '../config/config.service';

const lcidToUserflowLocale = { 1033: 'en-US', 1031: 'de-DE', 1036: 'fr-FR' };

@Injectable({
  providedIn: 'root',
})
export class UserflowService {

  private initDone: boolean = false;
  private pendingGroup: string = undefined;

  /**
   * Initialise userflow
   */
  constructor(
    private configService: ConfigService
  ) { }

  /**
   * Get the userflow object, ensuring it is initialised
   * @returns Userflow object or null
   */
  private userflow() {
    const userflow = globalThis.userflow;
    if (!!userflow && !this.initDone) {
      userflow.init(this.configService.userflowToken);
      this.initDone = true;
    }
    return userflow;
  }

  /**
   * Tie a user to the flow
   * Identify must occur before init
   * @param user The details of the user that has logged in
   */
  public identify(user: User, localeLcid: string, library: LibraryModel) {
    if (!user?.userId) {
      // return if no user, or userId has not been set
      return;
    }

    // Map locale to userflow code
    const localeCode = lcidToUserflowLocale[localeLcid] ? lcidToUserflowLocale[localeLcid] : 'en-US';

    // Identify the user
    this.userflow()?.identify(
      user.userId.toString(),
      {
        name: user.name,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        locale_code: localeCode,
        timezone: user.timezone,
        libraryAlias: library?.id,
        libraryName: library?.name,
        isAuthor: !!user.isAuthor,
        isContributor: !!user.isContributor,
        isLocalizer: !!user.isLocalizer,
        isReviewer: !!user.isReviewer,
        isSupervisor: !!user.isSupervisor,
        isEnterpriseEdition: !!user.isEnterpriseEdition,
        isProfessionalEdition: !!user.isProfessionalEdition,
        isGroupEdition: !!user.isGroupEdition,
        isTrialEdition: !!user.isTrialEdition,
        organizationName: user.organizationName,
        subscriptionExpiry: user.subscriptionExpiry?.toUTCString(),
        subscriptionWarning: user.subscriptionWarning?.toUTCString(),
      }
    );
    // If there is a group pending, set it now
    if (this.pendingGroup) {
      this.userflow()?.group(this.pendingGroup);
      this.pendingGroup = undefined;
    }
  }

  /**
   * Logs out and stops any flows
   */
  public reset() {
    this.userflow()?.reset();
  }

  /**
   * Change the user's locale/language at some point after login
   * Ignored if not identified yet (language is also set during identify)
   * @param localeLcid The locale LCID
   */
  public setLocale(localeLcid: string) {
    if (this.userflow()?.isIdentified()) {
      // Map locale to userflow code
      const localeCode = lcidToUserflowLocale[localeLcid] ? lcidToUserflowLocale[localeLcid] : 'en-US';
      this.userflow()?.updateUser(
        {
          locale_code: localeCode,
        }
      );
    }
  }

  /**
   * Associate the flow with a group
   * @param name The group name
   */
  public group(name: string) {
    if (this.userflow()?.isIdentified()) {
      this.userflow()?.group(name);
    }
    // Group can only be set after identify. If identify has not been called yet, remember it
    else {
      this.pendingGroup = name;
    }
  }

  /**
   * Start a flow with a specific ID
   * XXX: Likely only used for testing. Questions:
   *    Can we identify a flow by friendly name/string?
   *    If we have to use the unique ID, does it change (e.g. on save)?
   *    If we create the ID in development and then copy it to production, does the ID change?
   * @param flowId The unique ID of the flow
   */
  public start(flowId: string) {
    this.userflow()?.start(flowId);
  }

}
