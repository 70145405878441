import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LauncherService } from 'src/app/services/launcher/launcher.service';
import { LoggerService } from 'src/app/services/logger/logger.service';

@Component({
  selector: 'app-publishing',
  template: '',
})
export class PublishingComponent implements OnInit, OnDestroy {
  constructor(
    private launcher: LauncherService,
    private route: ActivatedRoute,
    private router: Router,
    private logger: LoggerService
  ) { }

  /**
   * Get app name and handle if this data is missing
   * @returns void, navigates to 404 page on missing data
   */
  ngOnInit() {
    const appName: string = this.route?.snapshot?.data?.feature;
    if (!appName) {
      // appName not available on route snapshot data in x component
      this.logger?.error('Encountered an unknown issue loading publishing');
      this.router?.navigateByUrl(this.router.createUrlTree(['Error/404']));
      return;
    }
    this.launchApp(appName);
  }

  /**
   * Process any custom extras for loading this child route
   * @param appName name of the app to load, taken from the current route
   */
  launchApp(appName: string): void {
    let subRoute: string;
    if (this.route?.snapshot?.paramMap?.has('id') && this.route?.snapshot?.paramMap?.has('action')) {
      subRoute = '/'
        + this.route.snapshot.paramMap.get('id').toLowerCase()
        + '/'
        + this.route.snapshot.paramMap.get('action').toLowerCase();
    }

    this.launcher.launch(appName, subRoute);
  }

  /**
   * Hide iframes when navigating away
   */
  ngOnDestroy() {
    this.launcher?.hideLaunchedApps();
  }
}
