import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ConfigService } from 'src/app/services/config/config.service';
import { LauncherService } from 'src/app/services/launcher/launcher.service';
import { Router } from '@angular/router';
import { MessengerService } from 'src/app/services/messenger/messenger.service';
import { Location } from '@angular/common';
import { FeatureService } from 'src/app/services/feature/feature.service';
import { SparkData } from 'src/app/models/sparkdata.model';
import { ResourceService } from 'src/app/services/resource/resource.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { Subscription } from 'rxjs';
import { RecentBookService } from 'src/app/services/recent-book/recent-book.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-launcher',
  template: `<div id="launchedAppWrapper" #launchedAppWrapper>
      <app-control-bar></app-control-bar>
    </div>`,
  // style imported into styles.scss
  styleUrls: ['./launcher.component.scss'],
})
export class LauncherComponent implements OnDestroy, OnInit {
  @ViewChild('launchedAppWrapper', { static: false }) launchedAppWrapper: ElementRef;
  private pollForReviewRouteChanges = null;
  private messengerServiceSubscription: Subscription;

  private hideLaunchedApp = 'forceHide';

  constructor(
    public launcherService: LauncherService,
    private messengerService: MessengerService,
    private renderer: Renderer2,
    private configService: ConfigService,
    private router: Router,
    private featureService: FeatureService,
    private resources: ResourceService,
    private location: Location,
    private logger: LoggerService,
    private recentBookService: RecentBookService,
    private spinnerService: SpinnerService,
    private authService: AuthenticationService,
    private toastService: ToastService
  ) {
    this.launcherService.attach(this);
  }

  ngOnInit() {
    this.messengerServiceSubscription = this.messengerService
      .getMessageListener()
      .subscribe((mess) => {
        this.messageReceived(mess.origin, mess.message, mess.data);
      });
  }

  ngOnDestroy() {
    if (this.pollForReviewRouteChanges) {
      clearInterval(this.pollForReviewRouteChanges);
      this.pollForReviewRouteChanges = null;
    }
    this.messengerServiceSubscription.unsubscribe();
    this.detachAllAppsFromMessenger();
    this.spinnerService.destroyAllSpinners();
  }

  //#region watch for navigation within review app
  private replaceURLWithoutRerouting(newUrl: string) {
    this.location.replaceState(newUrl);
  }

  private checkReviewRouteChanges(activeIFrame: HTMLIFrameElement): void {
    // if loaded and not hidden
    if (!!activeIFrame.contentWindow && !(activeIFrame?.parentElement?.classList.contains(this.hideLaunchedApp))) {
      const iFrameURL: string = activeIFrame?.contentWindow?.location?.href;
      // iframe Url probably doesnt exist at this point
      if (!iFrameURL || iFrameURL === 'about:blank') {
        return;
      }

      let reviewId: string = '';
      const queryStr = iFrameURL.split('#')[1];
      if (queryStr) {
        // Extract hash and libinfo
        const queryParams = new URLSearchParams('?hash=' + queryStr);
        let subRoute: string = '';
        if (queryParams.has('hash')) {
          reviewId = queryParams.get('hash');
          subRoute = '/' + reviewId;
          if (queryParams.has('libinfo')) {
            subRoute += '/' + queryParams.get('libinfo');
          }
        }
        // check if review is within current url, if not, recreate URL with the
        if (reviewId !== '' && !this.location.path()?.includes(reviewId)) {
          this.replaceURLWithoutRerouting(this.featureService.getFeatureByFeatureName('reviews').route + subRoute);
          this.featureService.updateRouteParams('reviews', subRoute);
        }
      } else {
        // no current review id in child route, if browser url contains a review id remove it
        if (this.location.path()?.match(RegExp('r[0-9]'))?.length > 0) {
          // review id format: r1234t1234 or r1234t1234m1234
          // remove review Id off url
          this.replaceURLWithoutRerouting(this.featureService.getFeatureByFeatureName('reviews').route);
          this.featureService.updateRouteParams('reviews');
        }
      }
    }
  }
  //#endregion

  /**
   * Launch application in an iframe
   * @param appName name of the application to be loaded
   * @param subRoute optional id passed in for loading a child route of the iframe directly
   */
  public launchApp(appName: string, subRoute?: string): void {
    // Confirm feature we are trying to load is valid
    const feature = this.featureService.getFeatureByFeatureName(appName);
    // Get base url of application to load in iframe
    let appBaseURL = this.configService[`${appName}Url`];

    if (!feature || !appBaseURL) {
      this.handleAppNotFound(appName);
      return;
    }

    if (!!subRoute) {
      // add subRoute to base url of iframe application
      appBaseURL = `${appBaseURL}${subRoute}`;
    }

    // intermittently on first load, if navigating directly to a child component,
    // the launchedAppWrapper ViewChild element and renderer instance does NOT exist yet
    const launchedAppWrapperElement = this.launchedAppWrapper?.nativeElement || document.getElementById('launchedAppWrapper');
    let launchedApp: HTMLDivElement = this.getLaunchedApp(appName);
    this.showLaunchedApp(launchedApp);

    // update the iframe src url when app has already launched, and user is navigating to a sub route within the app
    if (!!launchedApp) {
      const launchedIFrame = (launchedApp.firstElementChild as HTMLIFrameElement);
      // dont handle url changes for files or reviews application. review url changes are handled by a separate function
      if (appName !== 'files' && appName !== 'reviews') {
        if ((!!subRoute && (!launchedIFrame?.src?.includes(subRoute))) || !!feature.alwaysReloadIframe) {
          // iframe already launched, id passed in which is not already in the src of the iframe
          launchedIFrame.src = appBaseURL;
        }
      }
    }

    if (!launchedApp) {
      // app not already launched
      this.logger.info(`${feature.logName} accessed`,
        { action: 'application-open', actionType: 'access', details: { 'application-accessed': feature.logName }});

      // create a new iframe
      launchedApp = this.launchNewApp(appName, appBaseURL);

      if (!!launchedApp) {
        // add newly launched app to the wrapper for reuse
        launchedAppWrapperElement.appendChild(launchedApp);

        // now show the loading spinner <-- requires the code above to ensure the target element exists first
        if (!!feature.showloadingSpinner) {
          this.spinnerService.showSpinner(launchedApp);
        }

        // Attach the iframe to the messenger service so that Portal can communicate with the hosted app
        // If a messenger target has been set use that, otherwise default to the feature name
        this.messengerService.attach(feature.messengerTarget || feature.featureName, (launchedApp.firstElementChild as HTMLIFrameElement).contentWindow);
      }
    }

    if (!launchedApp) {
      this.handleAppNotFound(appName);
      return;
    } else {
      // watch for navigation within the review application
      if (appName === 'reviews') {
        this.pollForReviewRouteChanges = setInterval(
          () => this.checkReviewRouteChanges(launchedApp.firstElementChild as HTMLIFrameElement),
          this.configService.default.reviewRouteChangesPollInterval
        );
      }
    }
  }

  /**
   * Hide all loaded iframes
   */
  public hideLaunchedApps(): void {
    if (this.pollForReviewRouteChanges) {
      clearInterval(this.pollForReviewRouteChanges);
      this.pollForReviewRouteChanges = null;
    }

    for (const child of this.launchedAppWrapper?.nativeElement?.children) {
      // skip non iframes
      if (child.nodeName.toLowerCase() === 'app-control-bar') {
        continue;
      }
      child.classList.add(this.hideLaunchedApp);
    }
  }

  /**
   * Find previously loaded iframe
   * @param appName name of the app loaded in the iframe
   * @returns iframe if existing or null if not found
   */
  private getLaunchedApp(appName: string): HTMLDivElement {
    const launchedApp: HTMLDivElement = this.launchedAppWrapper?.nativeElement?.children?.namedItem(appName);
    return launchedApp;
  }

  /**
   * Show previously loaded iframe
   * @param launchedApp iframe element to show
   */
  private showLaunchedApp(launchedApp: HTMLDivElement): void {
    if (!!launchedApp) {
      // already exists so show as active iframe
      launchedApp.classList.remove(this.hideLaunchedApp);
    }
  }

  /**
   * Check if a specific app has launched and exists in the UI
   * @param appName The app name
   * @returns True if it exists, false otherwise
   */
  public appHasLaunched(appName: string): boolean {
    return !!this.launchedAppWrapper?.nativeElement?.children?.namedItem(appName);
  }

  /**
   * Create a new iframe to display
   * @param appName name of the app to be loaded in the iframe
   * @param src url of the application to load
   * @returns new iframe element to add to the launcher
   */
  private launchNewApp(appName: string, src: string): HTMLDivElement {
    let iframeWrapper: HTMLDivElement;
    let iframe: HTMLIFrameElement;
    // Intermittently, the renderer does NOT exist at this point
    iframe = this.renderer?.createElement('iframe');
    iframeWrapper = this.renderer.createElement('div');
    if (!iframe || !iframeWrapper) {
      // if the renderer was not available for some reason, default to basic doc.createEl func
      iframe = document.createElement('iframe');
      iframeWrapper = document.createElement('div');
    }
    iframe.id = 'iframe-' + appName;
    iframeWrapper.id = appName;

    iframe.src = src;

    // css for this dynamically created element is imported directly imported into styles.scss
    iframe.classList.add('applicationIFrame');
    iframeWrapper.classList.add('launchedApp');

    iframeWrapper.appendChild(iframe);

    return iframeWrapper;
  }

  /**
   * Log an error and navigate to 404 page
   * @param appName name of the app that was trying to load
   */
  private handleAppNotFound(appName: string): void {
    this.logger.error('app: ' + appName + ' is not valid');
    // TODO confirm if we should hide the loading spinners here
    this.router.navigateByUrl(this.router.createUrlTree(['Error/404']));
  }

  /**
   * Reload an app
   * @param appName The name of the app to reload
   */
  public reloadApp(appName: string): void {
    // Find the feature and exit if it doesn't exist
    const feature = this.featureService.getFeatureByFeatureName(appName);
    if (!feature) return;

    // Find the app
    const launchedApp: HTMLDivElement = this.getLaunchedApp(appName);
    if (!!launchedApp) {
      // See if we have access to the content window
      try {
        const contentWindow: Window = (launchedApp.firstElementChild as HTMLIFrameElement).contentWindow;
        if (!!contentWindow) {
          // Reload the window. Will throw exception if cross-site error encountered
          contentWindow.location.reload();
          // Start the spinner
          if (!!feature.showloadingSpinner) {
            this.spinnerService.showSpinner(launchedApp);
          }
          return;
        }
      }
      catch (e) {
        this.logger.debug('Error trying to reload the application, destroying and recreating instead');
      }
    }

    // If all else fails, destroy the app and reload it
    this.destroyLaunchedApp(appName);
    this.launchApp(appName);
  }

  /**
   * Close an app
   * @param appName The name of the app to Close
   */
  public closeApp(appName: string): void {
    this.destroyLaunchedApp(appName);
    if (appName === 'recentbook') {
      this.router.navigate([
        this.featureService.getFeatureByFeatureName('library').route,
      ]);
    }
    else {
      this.router.navigate([
        this.featureService.getFeatureByFeatureName('home').route,
      ]);
    }
  }

  /**
   * Detach messenger from all applications because all iFrames are about to be closed.
   */
  private detachAllAppsFromMessenger(): void {
    for (const child of this.launchedAppWrapper?.nativeElement?.children) {
      this.messengerService.detachByWindow((child.firstElementChild as HTMLIFrameElement)?.contentWindow);
    }
  }

  /**
   * Destroy / Remove launched app and iframe using the appName
   * @param appName name of the app loaded in the iframe
   */
  private destroyLaunchedApp(appName: string): boolean {
    // Find the IFrame
    const launchedApp: HTMLDivElement = this.getLaunchedApp(appName);
    if (!!launchedApp) {
      // Detach the messenger from it.
      if ((launchedApp.firstElementChild as HTMLIFrameElement).contentWindow) {
        this.messengerService.detachByWindow((launchedApp.firstElementChild as HTMLIFrameElement).contentWindow);
      }

      // Remove the launched app and IFrame from the DOM
      launchedApp.remove();
      // Remove spinner reference
      this.spinnerService.destroySpinner(appName);
      return true;
    }
    else
      return false;
  }

  /**
   * Called when a message is received from one of the applications in an iframe
   * @see ngOnInit
   * @see messengerService
   * @param origin The name of the application who sent the message. e.g. 'contribute'
   * @param message The message string
   * @param data Optional. Any data that accompanies the message
   */
  messageReceived(origin: string, message: string, data?: {}) {
    switch (origin) {
    case 'author':
      this.authorMessageReceived(message, data);
      break;
    case 'contribute':
      this.contributeMessageReceived(message, data);
      break;
    case 'review':
    case 'localize':
    case 'signoff':
      this.webApplicationsMessageReceived(message, data);
      break;
    case 'files':
      this.filesMessageReceived(message, data);
    }
  }

  /**
   * Process a message from the Spark app
   * @param message The message
   * @param data The data
   */
  authorMessageReceived(message: string, data?: {}) {
    switch (message) {
    // Spark is initialising and has requested data
    case 'init/request':
      // Prepare data to send back to spark
      const sparkData: SparkData = new SparkData();
      sparkData.gateway = this.configService.sparkGateway;
      sparkData.domain = this.configService.sparkDomain;
      sparkData.server = this.configService.sparkServer;
      sparkData.locale = this.resources.getLocale();
      sparkData.keyboard = this.authService.getUserProperty('keyboardLanguage');
      if (!sparkData.keyboard) {
        // If user has no selected keyboard language use locale
        sparkData.keyboard = this.resources.getLocaleLcid();
      }
      // Spark string resource
      sparkData.sparkLoading = this.resources.getString('sparkLoading');
      sparkData.sparkConnecting = this.resources.getString('sparkConnecting');
      sparkData.sparkAuthenticating = this.resources.getString('sparkAuthenticating');
      sparkData.sparkStarting = this.resources.getString('sparkStarting');
      // Send data
      this.messengerService.sendMessage('author', 'init/provide', sparkData);
      break;
    case 'exit':
      if (this.destroyLaunchedApp('author') && this.router.url === this.featureService.getFeatureByFeatureName('author').route) {
        // Change the route to the home screen
        this.router.navigate([this.featureService.getFeatureByFeatureName('home').route]);
      }
      break;
    case 'init/toast':
      // Call toast notification.
      let bar: any = {}; bar = data;
      if (bar.sparkname) {
        this.toastService.showToast(this.resources.localisedStrings.authorTitle, bar.msg);
      }
      break;
    }
  }

  /**
   * Process a message from Web Applications such as Translations, Reviews and etc
   * @param message The message
   * @param data The data
   */
  webApplicationsMessageReceived(message: string, data?: {}) {
    switch (message) {
    case 'review/loaded':
      this.appLoaded('reviews');
      break;
    case 'signoff/loaded':
      this.appLoaded('signoff');
      break;
    case 'localize/loaded':
      this.appLoaded('translations');
      break;
    default:
      break;
    }
  }

  /**
   * Process a message from files application
   * @param message The message
   * @param data The data
   */
  filesMessageReceived(message: string, data?: {}) {
    switch (message) {
    case 'files/loaded':
      this.appLoaded('files');
      break;
    default:
      break;
    }
  }

  /**
   * Process a message from Contribute
   * @param message The message
   * @param data The data
   */
  contributeMessageReceived(message: string, data?: {}) {
    switch (message) {
    case 'book/show':
    case 'publish/show':
      // request to show a book
      const bookId = 'bookId';
      const bookName = 'bookName';
      // confirm bookId sent in data and is a number
      if (data && data[bookId] && !!Number.parseInt(data[bookId], 0)) {
        const feature = this.featureService.getFeatureByFeatureName('recentbook');

        const bookLaunchedApp = this.getLaunchedApp(feature.featureName);
        const currentlyLoadedBook = this.recentBookService.getRecentBook();
        if (!bookLaunchedApp || currentlyLoadedBook.bookId !== data[bookId]) {
          // Show loading spinner before loading book module, or navigating to different book
          this.spinnerService.showSpinner(this.getLaunchedApp(feature.featureName));
        }

        if (!!bookLaunchedApp && currentlyLoadedBook.bookId !== data[bookId]) {
          // book app launched but now loading a different book
          this.logger.info(`${feature.logName} accessed`,
            { action: 'application-open', actionType: 'access', details: { 'application-accessed': feature.logName }});
        }

        this.recentBookService.storeRecentBook(data[bookId], data[bookName]);
        this.featureService.addRecentBook(data[bookName]);

        this.router.navigate([
          feature.route,
          data[bookId],
          // Encoding this results in double encoded characters when navigating to the URL
          (bookName ? { name: data[bookName] } : ''),
        ]);
      }
      break;
    case 'library/loaded':
      this.appLoaded('library');
      break;
    case 'publish/loaded':
      this.appLoaded('publishing');
      break;
    case 'book/loaded':
      this.appLoaded('recentbook');
      break;
    case 'settings/loaded':
      this.appLoaded('settings');
      break;
    case 'profile/loaded':
      this.appLoaded('profile');
      break;
    default:
      break;
    }
  }

  /**
   * set feature as loaded and hide loading spinner
   * @param featureName name of feature to hide loading spinner for
   */
  appLoaded(featureName: string): void {
    const feat = this.featureService.getFeatureByFeatureName(featureName);
    if (!feat?.featureName) {
      return;
    }

    this.spinnerService.hideSpinner(feat.featureName);
  }

}
