
import { Component, OnDestroy, OnInit } from '@angular/core';
import { HomePageService } from 'src/app/services/home-page/home-page.service';
import { ResourceService } from 'src/app/services/resource/resource.service';
import { FeatureService } from 'src/app/services/feature/feature.service';
import { KanbanCardData } from 'src/app/models/kanban-card-data.model';
import { ConfigService } from 'src/app/services/config/config.service';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/services/util/util.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {

  /**
   * Card data for displaying on the kanban
   */
  public cardItems: KanbanCardData[] = [];

  public columns = {
    recent: 'recent',
    import: 'import',
    create: 'create',
    review: 'review',
    translate: 'translate',
    publish: 'publish',
  };
  private pollForRecentItems = null;

  constructor(
    private homePageService: HomePageService,
    private configService: ConfigService,
    public resources: ResourceService,
    private featureService: FeatureService,
    private router: Router,
    private utils: UtilService
  ) { }

  ngOnInit(): void {
    this.getStaticItems();
    this.getRecentItems();
    this.pollForRecentItems = setInterval(
      () => this.getRecentItems(),
      this.configService.default.homePageRecentItemsPollInterval
    );
  }

  ngOnDestroy() {
    if (this.pollForRecentItems) {
      clearInterval(this.pollForRecentItems);
      this.pollForRecentItems = null;
    }
  }

  /**
   * Handle external and internal url routing on card link click
   * @param event default click event
   * @param url url to navigate to
   */
  public onCardLinkClick(event: PointerEvent, url: string): void {
    event.preventDefault();
    if (!url || !url.trim()) {
      return;
    }

    if (url.startsWith('https://') || url.startsWith('http://')) {
      // external link, open in new tab
      this.utils.window().open(url, '_blank');
    } else {
      // navigate within application
      this.router.navigate([url]);
    }
  }

  /**
   * Checks for duplicates based on the id, and adds unique cards to the kanban
   * @param newCards cards to add
   * @param replaceColumn
   * @returns
   */
  private addCardsToKanban(newCards: KanbanCardData[], replaceColumn?: string): void {
    // are there cards to add
    if (!newCards || newCards.length === 0) {

      if (!!replaceColumn && !!replaceColumn.trim()) {
        // no cards to add, but we want to replace a column, so clear it.
        this.cardItems = [...this.cardItems.filter((card) => card.column !== replaceColumn)];
      }
      return;
    }

    const currentCards: KanbanCardData[] = [];
    if (!!replaceColumn && !!replaceColumn.trim()) {
      // we want to replace all cards in a column, so filter existing ones out
      currentCards.push(...this.cardItems.filter((card) => card.column !== replaceColumn));
    } else {
      currentCards.push(...this.cardItems);
    }

    // prevent possible duplicate cards, replace any existing with new
    this.cardItems = [...currentCards.filter((card) => !newCards.find((item) => item.id === card.id)), ...newCards];
  }

  /**
   * Get recent items
   */
  private async getRecentItems() {
    const publishingJobs = await this.homePageService.getRecentPublishingJobs();

    if (publishingJobs && publishingJobs.length > 0) {
      // dont want leading /
      const recentBookBaseUrl = this.featureService.getFeatureByFeatureName('recentbook').route.slice(1);

      // map publishing job to a kanban card
      this.addCardsToKanban(publishingJobs.map<KanbanCardData>(
        (item) => {
          return {
            id: 'pub-job-' + item.jobId,
            column: this.columns.recent,
            title: item.bookDescription,
            summary: this.resources.localisedStrings.submitted + ': '
              + this.utils.formatDate(new Date(item.submittedTime), this.configService.default.recentItemsDateTimeFormat),
            buttonText: this.resources.localisedStrings.edit,
            buttonIcon: 'recentBook',
            cardClass: '',
            url: recentBookBaseUrl + '/' + item.bookId,
            subtitle: 'outputFormat' + item.format,
          };
        })
      );
    }
  }

  /**
   * Get static card items
   */
  private async getStaticItems() {
    const otherItems = await this.homePageService.getOtherItems();
    if (otherItems && otherItems.length > 0) {
      this.addCardsToKanban(otherItems.map<KanbanCardData>(
        (item, index) => {
          return {
            id: 'static-' + index,
            column: item.column,
            title: this.resources.localisedStrings[item.title],
            summary: this.resources.localisedStrings[item.summary],
            buttonText: this.resources.localisedStrings[item.buttonText],
            buttonIcon: item.buttonIcon,
            cardClass: item.cardClass,
            url: item.url,
          };
        })
      );
    }
  }

}
