<app-shared-dialog #completeReviewDialog [customSettings]="dialogSettings" [content]="content"></app-shared-dialog>
<ng-template #content>
  <h3>{{(data.dialogTitle)}}</h3>
  <div class="dialogContent">
    <ng-template [ngIf]="isCollaborationReview">
      <span>
        {{this.resources.localisedStrings.completeReviewDialogDescription}}
        {{this.resources.localisedStrings.cannotBeUndone}}
      </span>

    </ng-template>
    <!-- specifically false, not undefined -->
    <ng-template [ngIf]="isCollaborationReview === false">
      <h4>{{data.bookName}}</h4>
      <p>
        <span class="dateSection"><span class="dateLabel">{{data.startDateLabel}} </span>{{data.startDate}}</span>
        <span><span class="dateLabel">{{data.endDateLabel}} </span>{{data.endDate}}</span>
      </p>

      <p>
        {{data.summaryText}}
      </p>

      <p>
        <ng-template *ngIf="approvedNodes.length <= 0; then fail else success"></ng-template>
        {{data.completeReviewApproved}}
      </p>
      <p>
        <ng-template *ngIf="reviewNodes.length > 0; then fail else success"></ng-template>
        {{data.completeReviewToBeApproved}}
      </p>
      <p>
        <ng-template *ngIf="rejectedNodes.length > 0; then fail else success"></ng-template>
        {{data.completeReviewRejected}}
      </p>
      <p>
        <ng-template *ngIf="otherNodes.length > 0; then fail else success"></ng-template>
        {{data.completeReviewOther}}
      </p>

      <ng-template #fail>
        <app-icon iconName="cross" fixedWidth customClass="red"></app-icon>
      </ng-template>
      <ng-template #success>
        <app-icon iconName="tick" fixedWidth customClass="green"></app-icon>
      </ng-template>

      <hr>
      <ejs-checkbox *ngIf="data.showCheck" [label]="data.checkLabel" [checked]="data.checked"
        (change)="data.checked = !data.checked" (click)="validateCanComplete()">
      </ejs-checkbox>

    </ng-template>
  </div>
  <div class="dialogFooter">
    <button type="button" ejs-button cssClass="e-primary" [disabled]="disableComplete"
      (click)="completeReview()">{{resources.localisedStrings.complete}}</button>
    <button type="button" ejs-button cssClass="e-info"
      (click)="hideDialog()">{{resources.localisedStrings.cancel}}</button>
  </div>
  <button ejs-button cssClass="e-flat icon close" title="{{resources.localisedStrings.closeLabel}}"
    (click)="hideDialog()">
    <app-icon iconName="close"></app-icon>
  </button>
</ng-template>