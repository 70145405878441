import { Injectable, Inject, Optional } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Message } from 'src/app/models/message.model';
import { LoggerService } from '../logger/logger.service';
import { UtilService } from '../util/util.service';

/**
 * -----
 *
 * MESSENGER SERVICE
 *
 * This is a service to allow communication between the portal app and the AIT child apps that
 * are loaded in via an iframe. It allows two-way communication using postMessage.
 *
 * A message consists of:
 * {
 *    origin: String. Unique name or ID of the message sender (e.g. "portal")
 *    message: String. Short message type or action type (e.g. "user/loaded", "book/opened", "settings/saved")
 *    data: Object. Optional. Any additional data to accompany the message (e.g. { id: 1234 })
 */
@Injectable({
  providedIn: 'root',
})
export class MessengerService {

  /**
   * The name by which we are known when a message originates from us (passed in the constructor)
   */
  private origin: string;

  /**
   * The postoffice is an object which holds a reference to each target iframe. If the target does not
   * exist, it stores the messages until such a time that the target exists.
   * postoffice = {
   *    'contribute': {
   *        window: null,       // Or the window object for this target
   *        messages: [ ... ],  // Messages received for target
   *    },
   *    'review': {
   *        window: reviewWIndow,
   *        message: []
   *    }
   * }
   */
  private postoffice: {} = {};

  /**
   * RXJS observable that can cast messages to many observers. @see getMessageListener
   */
  private messageReceived = new Subject<Message>();

  /**
   * Set up session storage listener to receive events from applications
   * @param   origin    The name of the origin (portal). Because the MessengerService is created automatically
   *                    by angular, we can't specify origin when it is created. Instead, add a provider to
   *                    app.module.ts called 'whoAmI', like this: {provide: 'whoAmI', useValue: 'portal'}
   */
  constructor(
    private logger: LoggerService,
    private utils: UtilService,
    @Inject('whoAmI') @Optional() public whoAmI?: string
  ) {
    this.origin = whoAmI || 'unknown';
    this.utils.window().addEventListener('message', (event) => this.receiveMessage(event));
  }

  /**
   * Listener that notifies every time a message is received for the Portal (where target is portal)
   * @returns Observable that notifies every time there is a new message received for the Portal
   */
  public getMessageListener(): Observable<Message> {
    return this.messageReceived.asObservable();
  }

  /**
   * Attach a child window to the messenger service. This allows the service to listen for messages on that window
   * and to send messages to that target.
   * @param window  Window of the target application
   * @param target  Unique name or ID of the target application
   */
  public attach(target: string, window: Window) {
    // If the target already exists
    if (this.postoffice[target]) {
      // Attach the window
      this.postoffice[target].window = window;
      // Send any queued messages
      for (const message of this.postoffice[target].messages) {
        this.postoffice[target].window.postMessage(message, '*');
      }
    }
    // Target doesn't yet exist
    else {
      // Create target
      this.postoffice[target] = {
        window: window,
        messages: [],
      };
    }
  }

  /**
   * Detach a previously attached window. Probably never used
   * @param target Unique name or ID of the target application
   */
  public detach(target: string) {
    // If the target already exists
    if (this.postoffice[target]) {
      // Just remove reference to window, but leave the postoffice entry so that messages can still be queued.
      this.postoffice[target].window = null;
    }
  }

  /**
   * Detach a previously attached window by specifying the window
   * @param window The window to dettach
   * @returns Nothing
   */
  public detachByWindow(window: Window) {
    let i;
    for (i in this.postoffice) {
      if (this.postoffice.hasOwnProperty(i)) {
        if (this.postoffice[i].window === window) {
          delete this.postoffice[i];
          return;
        }
      }
    }
  }

  /**
   * Send a message from Portal to a child application
   * @param   target      The name of the target application
   * @param   message     The message to send
   * @param   data        Any data that accompanies the message
   * @param   allowQueue  If the window is not attached, setting this to true will queue the
   *                      message until the window is attached. If this is false, sendMessage
   *                      will return false (failed to send message).
   * @return True if the message was sent or queued, false if the message failed
   */
  public sendMessage(target: string, message: string, data: {}, allowQueue: boolean = false): boolean {
    this.logger.debug('Portal: sendMessage to ' + target);

    // Does the target exist?
    if (this.postoffice[target]) {

      // Is the target attached?
      if (this.postoffice[target].window) {
        // Send the message
        this.postoffice[target].window.postMessage({
          message: message,
          origin: this.origin,
          data: data,
        }, '*');
        return true;
      }

      // Target not attached
      else if (allowQueue) {
        // Queue message
        this.postoffice[target].messages.push({
          message: message,
          origin: this.origin,
          data: data,
        });
        return true;
      }

    }

    // Target does not exist
    else if (allowQueue) {
      // Create target and queue message
      this.postoffice[target] = {
        window: null,
        messages: [{
          message: message,
          origin: this.origin,
          data: data,
        }],
      };
      return true;
    }

    return false;
  }

  /**
   * Called when a message is received from a child window
   */
  private receiveMessage(event: MessageEvent) {
    let logInfo = '';
    if (event.data?.origin && event.data?.message) {
      logInfo = JSON.stringify(event.data);
    }
    this.logger.debug('Portal: receiveMessage ' + logInfo);

    // Quick security check to see if message came from same origin (Cross-site scripting attack). Allow any origin on localhost
    if (event.origin !== this.utils.window().origin && this.utils.window().location.hostname !== 'localhost') {
      this.logger.warn('Messenger ERROR: Message received from potentially unsafe source "' + event.origin + '" (expecting "' + this.utils.window().origin + '")');
      return;
    }

    this.messageReceived.next({
      origin: event.data.origin,
      message: event.data.message,
      data: event.data.data,
    });
  }

}
