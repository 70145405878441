import { Injectable } from '@angular/core';
import {
  MenuPosition,
  FeatureItemModel,
} from 'src/app/models/feature-item.model';

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  public features: FeatureItemModel[] = [
    {
      featureName: 'home',
      titleRef: 'homeTitle',
      route: '/Home',
      icon: 'home',
      menu: MenuPosition.top,
    },
    {
      featureName: 'library',
      logName: 'library',
      titleRef: 'libraryTitle',
      route: '/Library',
      icon: 'library',
      menu: MenuPosition.top,
      messengerTarget: 'contribute',
      showControlBar: true,
      showloadingSpinner: true,
      controlBarAllowReload: true,
      controlBarAllowClose: true,
    },
    {
      featureName: 'recentbook',
      logName: 'recent-book',
      titleRef: 'recentBookTitle',
      exactTitle: '',
      route: '/Book',
      icon: 'recentBook',
      menu: MenuPosition.top,
      hidden: true,
      messengerTarget: 'contribute',
      showControlBar: true,
      showloadingSpinner: true,
      controlBarAllowReload: true,
      controlBarAllowClose: true,
    },
    {
      featureName: 'publishing',
      logName: 'publishing',
      titleRef: 'publishingTitle',
      route: '/Publishing',
      icon: 'publish',
      menu: MenuPosition.top,
      messengerTarget: 'contribute',
      showControlBar: true,
      showloadingSpinner: true,
      controlBarAllowReload: true,
      controlBarAllowClose: true,
    },
    {
      featureName: 'reviews',
      logName: 'reviews',
      titleRef: 'reviewsTitle',
      route: '/Reviews',
      icon: 'review',
      menu: MenuPosition.top,
      messengerTarget: 'review',
      showControlBar: true,
      showloadingSpinner: true,
      controlBarAllowReload: true,
      controlBarAllowClose: true,
    },
    {
      featureName: 'signoff',
      logName: 'sign-off',
      titleRef: 'signOffTitle',
      route: '/Signoff',
      icon: 'signoff',
      menu: MenuPosition.top,
      hidden: true,
      showControlBar: true,
      showloadingSpinner: true,
      controlBarAllowReload: true,
      controlBarAllowClose: true,
    },
    {
      featureName: 'translations',
      logName: 'translations',
      titleRef: 'translationsTitle',
      route: '/Translations',
      icon: 'localise',
      menu: MenuPosition.top,
      messengerTarget: 'localize',
      showControlBar: true,
      showloadingSpinner: true,
      controlBarAllowReload: true,
      controlBarAllowClose: true,
    },
    {
      featureName: 'files',
      logName: 'files',
      titleRef: 'filesTitle',
      route: '/Files',
      icon: 'files',
      showloadingSpinner: true,
      menu: MenuPosition.top,
    },
    {
      featureName: 'author',
      logName: 'author-spark',
      titleRef: 'authorTitle',
      route: '/Author',
      icon: 'author',
      menu: MenuPosition.top,
      action: 'author',
    },
    {
      featureName: 'help',
      titleRef: 'helpTitle',
      route: 'https://kc.author-it.com/',
      icon: 'help',
      externalUrl: true,
      menu: MenuPosition.bottom,
    },
    {
      featureName: 'settings',
      logName: 'settings',
      titleRef: 'settingsTitle',
      route: '/Settings',
      icon: 'settings',
      menu: MenuPosition.bottom,
      messengerTarget: 'contribute',
      alwaysReloadIframe: true,
      showControlBar: true,
      showloadingSpinner: true,
      controlBarAllowReload: true,
      controlBarAllowClose: true,
    },
    {
      featureName: 'profile',
      logName: 'profile',
      titleRef: 'profileTitle',
      route: '/Profile',
      icon: 'profile',
      menu: MenuPosition.bottom,
      messengerTarget: 'contribute',
      alwaysReloadIframe: true,
      showControlBar: true,
      showloadingSpinner: true,
      controlBarAllowReload: true,
      controlBarAllowClose: true,
    },
    {
      featureName: 'logout',
      titleRef: 'logOutTitle',
      route: '/Logout',
      icon: 'logout',
      action: 'logout',
      menu: MenuPosition.bottom,
    },
    // {
    //   featureName: 'login',
    //   title: 'logInTitle',
    //   route: '/Login',
    //   icon: 'login',
    //   menu: menuPosition.none,
    // },
  ];

  /**
   * Get a feature item from the defined list by featureName
   * @param name FeatureName to search for
   * @returns Feature item if found, or undefined if not found
   */
  getFeatureByFeatureName(name: string): FeatureItemModel {
    return this.features?.find((item) => item.featureName === name);
  }

  /**
   * Add book name to recent book menu item as displayed title
   * @param bookName title of a book
   */
  addRecentBook(bookName: string): void {
    const recentBook = this.features?.find((f) => f.featureName === 'recentbook');
    if (bookName === recentBook.exactTitle) {
      return;
    }
    recentBook.exactTitle = bookName || '';
    if (recentBook.hidden) {
      recentBook.hidden = false;
    }
  }

  /**
   * Update menu item URL
   * @param name Feature name
   * @param routeExtras String to concat to the end of the base route
   * @param queryParams Object of query parameters to add to the route
   */
  updateRouteParams(name: string, routeExtras?: string, queryParams?: {}): void {
    // TODO implement for all features that have child routes
    if (!name) {
      return;
    }
    const feat = this.features?.find((item) => item.featureName === name);
    if (!feat) {
      return;
    }
    // add if defined or reset to default
    feat.routeExtras = routeExtras || '';
    feat.queryParams = queryParams || {};
  }

  /**
   * Show signoff menu item (hidden by default)
   * @param show
   */
  showSignoff(show: boolean) {
    const signoff = this.features?.find((f) => f.featureName === 'signoff');
    // if hidden status already set to passed in param, dont make any change
    if (signoff?.hidden === !show) {
      return;
    }
    signoff.hidden = !show;
  }

  resetRecentBook(): void {
    const recentBookMenu = this.features?.find((item) =>
      item.featureName == 'recentbook');
    recentBookMenu.hidden = true;
    recentBookMenu.exactTitle = '';
    this.updateRouteParams('recentbook');
  }

  resetAllRoutes(): void {
    this.features.forEach((feat) => {
      this.updateRouteParams(feat.featureName);
    });
  }
}