/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { HttpService } from '../http/http.service';
// import { environment } from 'src/environments/environment';

// TODO confirm log levels, all and off should only be used for browser logging
export enum LogLevel {
  all = 0,
  debug = 1,
  info = 2,
  warn = 3,
  exception = 4, // error fatal exception etc
  off = 5,
}

type logDataModel = {
  'log-level': string,
  'user-id'?: string,
  'user-id-type'?: string,
  'created-date': string,
  'application': string,
  'application-version'?: string,
  'action'?: string,
  'action-type'?: string,
  'message': string,
  'details'?: {},
};

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  private levelToLog: LogLevel = LogLevel.off;
  private levelSetFromConfig: boolean = false;
  private userId: string = '';

  constructor(
    private configService: ConfigService,
    private httpService: HttpService,
  ) { }

  public setUserId(userId: string) {
    this.userId = userId || '';
  }

  public debug(msg: string, data?: {action?: string, actionType?: string, details?: {}}) {
    this.writeLog(msg, LogLevel.debug, data);
  }
  public info(msg: string, data?: {action?: string, actionType?: string, details?: {}}) {
    this.writeLog(msg, LogLevel.info, data);
  }
  public warn(msg: string, data?: {action?: string, actionType?: string, details?: {}}) {
    this.writeLog(msg, LogLevel.warn, data);
  }
  public error(msg: string, data?: {action?: string, actionType?: string, details?: {}}) {
    this.writeLog(msg, LogLevel.exception, data);
  }

  private writeLog(
    msg: string,
    level: LogLevel,
    data: {action?: string, actionType?: string, details?: {}} = {}
  ): void {
    // log verbose with stack trace for local dev
    if (location.hostname.startsWith('localhost')) {
      console.groupCollapsed('logger');
      console.trace('level ' + LogLevel[level], msg, data);
      console.groupEnd();
      return;
    }

    if (this.shouldLogToBrowser(level)) {
      let val = new Date().toLocaleString();
      val += ' - Type: ' + LogLevel[level];
      val += ' - Message: ' + msg;
      val += ' - Info: ' + JSON.stringify(data);
      console.log(val);
    }

    const logData: logDataModel = {
      'log-level':  LogLevel[level].toLowerCase(),
      'user-id': this.userId, // user id only available if authenticated
      'user-id-type': !!this.userId ? 'library-user' : '', // user id only available if authenticated
      'created-date': (new Date()).toISOString(),
      'application': 'portal',
      // version is currently not updated anywhere, environment.version pulls from the package.json
      // 'application-version': environment.version,
      'action': data.action || '',
      'action-type': data.actionType || '',
      'details': data.details || {},
      'message': msg,
    };

    // Fire and forget
    this.httpService.post('v1/logging', logData);
  }

  /**
   * Check if we want to write the log message
   * @param level level of message we want to log
   * @returns true if the log should be written
   */
  private shouldLogToBrowser(level: LogLevel): boolean {
    if (!this.levelSetFromConfig) {
      if (this.configService?.loggingLevel && LogLevel[this.configService.loggingLevel.toLowerCase()]) {
        this.levelToLog = LogLevel[this.configService.loggingLevel.toLowerCase()];
      }
      this.levelSetFromConfig = true;
    }

    let shouldLog: boolean = false;
    // level is equal or higher than levelToLog, or levelToLog is set to all
    if ((level >= this.levelToLog && level !== LogLevel.off) || this.levelToLog === LogLevel.all) {
      shouldLog = true;
    }
    return shouldLog;
  }
}
