import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlMatchResult, UrlSegment } from '@angular/router';
import { FeatureFlagGuard } from './services/feature-flags/feature-flag.guard';
import { ErrorComponent } from './components/error/error.component';
import { LibraryComponent } from './components/launcher/app-launcher/library/library.component';
import { ReviewsComponent } from './components/launcher/app-launcher/reviews/reviews.component';
import { SignOffComponent } from './components/launcher/app-launcher/signoff/signoff.component';
import { RecentBookComponent } from './components/launcher/app-launcher/recent-book/recent-book.component';
import { PublishingComponent } from './components/launcher/app-launcher/publishing/publishing.component';
import { TranslationsComponent } from './components/launcher/app-launcher/translations/translations.component';
import { FilesComponent } from './components/launcher/app-launcher/files/files.component';
import { AuthorComponent } from './components/launcher/app-launcher/author/author.component';
import { SettingsComponent } from './components/launcher/app-launcher/settings/settings.component';
import { ProfileComponent } from './components/launcher/app-launcher/profile/profile.component';
import { HomeComponent } from './components/home/home.component';
import { AuthenticationGuard } from './services/authentication/authentication.guard';
import { LoginComponent } from './components/login/login.component';
import { RedirectComponent } from './components/redirect/redirect.component';

/**
 * custom route matcher for handling routing of the child applications (contribute, review etc)
 * @param segments all segments of the route
 * @param baseRouteName The start of the 'path' we are navigating to
 * @returns result of matching the url
 */
const featureAppRouteMatcher = (segments: UrlSegment[], baseRouteName): UrlMatchResult => {
  if (segments.length > 0 && segments[0].path === baseRouteName) {
    if (segments.length === 1) {
      return {
        consumed: segments,
        posParams: {},
      } as UrlMatchResult;
    }
    if (segments.length === 2) {
      return {
        consumed: segments,
        posParams: { id: segments[1] },
      } as UrlMatchResult;
    }
    if (segments.length === 3) {
      return {
        consumed: segments,
        posParams: { id: segments[1], action: segments[2] },
      } as UrlMatchResult;
    }
    return null as UrlMatchResult;
  }
  return null as UrlMatchResult;
};

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'Login',
    pathMatch: 'full',
  },
  {
    path: 'Login/ResetPassword',
    // SSO user is not allowed to access route, so must include a guard here
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./lazy-loaded/reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
  },
  {
    path: 'Login',
    component: LoginComponent,
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: 'SelectLibrary',
        component: LoginComponent,
        canActivate: [AuthenticationGuard],
      },
    ],
  },
  {
    path: 'Home',
    component: HomeComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'Redirect',
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: '**',
        component: RedirectComponent,
      },
    ],
  },
  {
    path: 'Library',
    component: LibraryComponent,
    canActivate: [FeatureFlagGuard, AuthenticationGuard],
    data: { feature: 'library' },
  },
  {
    matcher: (segments) => featureAppRouteMatcher(segments, 'Book'),
    component: RecentBookComponent,
    canActivate: [FeatureFlagGuard, AuthenticationGuard],
    data: { feature: 'recentbook' },
  },
  {
    matcher: (segments) => featureAppRouteMatcher(segments, 'Publishing'),
    component: PublishingComponent,
    canActivate: [FeatureFlagGuard, AuthenticationGuard],
    data: { feature: 'publishing' },
  },
  {
    matcher: (segments) => featureAppRouteMatcher(segments, 'Reviews'),
    component: ReviewsComponent,
    canActivate: [FeatureFlagGuard, AuthenticationGuard],
    data: { feature: 'reviews' },
  },
  {
    matcher: (segments) => featureAppRouteMatcher(segments, 'Signoff'),
    component: SignOffComponent,
    canActivate: [FeatureFlagGuard, AuthenticationGuard],
    data: { feature: 'signoff' },
  },
  {
    path: 'Translations',
    component: TranslationsComponent,
    canActivate: [FeatureFlagGuard, AuthenticationGuard],
    data: { feature: 'translations' },
  },
  {
    path: 'Files',
    component: FilesComponent,
    canActivate: [FeatureFlagGuard, AuthenticationGuard],
    data: { feature: 'files' },
  },
  {
    path: 'Author',
    component: AuthorComponent,
    canActivate: [FeatureFlagGuard, AuthenticationGuard],
    data: { feature: 'author' },
  },
  {
    matcher: (segments) => featureAppRouteMatcher(segments, 'Settings'),
    component: SettingsComponent,
    canActivate: [FeatureFlagGuard, AuthenticationGuard],
    data: { feature: 'settings' },
  },
  {
    path: 'Profile',
    component: ProfileComponent,
    canActivate: [FeatureFlagGuard, AuthenticationGuard],
    data: { feature: 'profile' },
  },
  {
    path: 'Error/:type',
    component: ErrorComponent,
  },
  {
    path: '**',
    redirectTo: 'Error/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
  providers: [AuthenticationGuard],
})
export class AppRoutingModule { }
