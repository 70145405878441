import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FeatureService } from 'src/app/services/feature/feature.service';
import { LauncherService } from 'src/app/services/launcher/launcher.service';
import { LoggerService } from 'src/app/services/logger/logger.service';

@Component({
  selector: 'app-signoff',
  template: '',
})
export class SignOffComponent implements OnInit, OnDestroy {
  constructor(
    private launcher: LauncherService,
    private route: ActivatedRoute,
    private router: Router,
    private logger: LoggerService,
    private featureService: FeatureService
  ) { }

  /**
   * Get app name and handle if this data is missing
   * @returns void, navigates to 404 page on missing data
   */
  ngOnInit() {
    const appName: string = this.route?.snapshot?.data?.feature;
    if (!appName) {
      // appName not available on route snapshot data in x component
      this.logger?.error('Encountered an unknown issue loading signoff');
      this.router?.navigateByUrl(this.router.createUrlTree(['Error/404']));
      return;
    }
    this.launchApp(appName);
  }

  /**
   * Process any custom extras for loading this child route
   * @param appName name of the app to load, taken from the current route
   */
  launchApp(appName: string): void {
    // Show Signoff in menu
    this.featureService.showSignoff(true);

    // Add query params to load correct review
    let subRoute: string = '';
    if (this.route?.snapshot?.url?.length > 1) {
      subRoute = '?review=' + this.route?.snapshot.url[1];
      if (this.route.snapshot.url.length > 2) {
        subRoute += '&libinfo=' + this.route?.snapshot.url[2];
      }
    }

    this.featureService.updateRouteParams(appName, subRoute);
    this.launcher.launch(appName, subRoute);
  }

  /**
   * Hide iframes when navigating away
   */
  ngOnDestroy() {
    this.featureService.showSignoff(false);
    this.launcher?.hideLaunchedApps();
  }
}
