<div *ngIf="showPortal(); then portal else fullscreen"></div>
<ng-template #portal>
  <app-side-panel></app-side-panel>
  <div class="main">

    <div class="workspace">
      <router-outlet></router-outlet>
      <app-launcher></app-launcher>
    </div>
  </div>
</ng-template>
<ng-template #fullscreen>
  <router-outlet></router-outlet>
</ng-template>
<div id="ej2Toast" #toast></div>

<span class="testOnlyDisplayData" *ngIf="showTestData">
  V{{versionNumber}} - Library: {{authService.getLoadedLibraryAlias()}}
</span>