import { Injectable } from '@angular/core';
import {
  createSpinner as createSFSpinner,
  hideSpinner as hideSFSpinner,
  showSpinner as showSFSpinner,
  SpinnerArgs,
} from '@syncfusion/ej2-angular-popups';
import { ConfigService } from '../config/config.service';
import { LoggerService } from '../logger/logger.service';
import { ResourceService } from '../resource/resource.service';
import { ToastService } from '../toast/toast.service';

/**
 * Identifying features of created spinners
 */
type SpinnerInstance = {
  id: string;
  element: HTMLElement
  activeTimeout?: any; // timeout
};

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private spinnerInstances: SpinnerInstance[] = [];
  constructor(
    private configService: ConfigService,
    private logger: LoggerService,
    private toastService: ToastService,
    private resources: ResourceService
  ) { }

  /**
   * Get created spinner instance
   * @param id Unique id to find spinner instance
   * @returns Spinner instance if found, or undefined if not
   */
  private getSpinnerInstance(id: string): SpinnerInstance {
    return this.spinnerInstances.find((s) => s?.id === id);
  }

  /**
   * Create spinner instance
   * @param spinnerArgs configurable spinner options, requires at minimum a target
   */
  public createSpinner(spinnerArgs: SpinnerArgs): SpinnerInstance {
    if (!spinnerArgs?.target?.id || this.getSpinnerInstance(spinnerArgs?.target?.id)) {
      this.logger.debug('createSpinner: spinner args invalid, or spinner already exists');
      return;
    }

    createSFSpinner({
      target: spinnerArgs.target,
      label: spinnerArgs.label,
      cssClass: spinnerArgs.cssClass,
      template: (spinnerArgs.template || `<div class="loading-spinner-ui-mask"></div><div class="loading-spinner">
      <img class="spinner-image-inside" src="assets/img/ait-spinner-logo.svg" />
      <img class="spinner-image-outside" src="assets/img/ait-spinner.svg" />
    </div>`),
      type: spinnerArgs.type,
      width: spinnerArgs.width,
    });

    const newSpinner = {
      id: spinnerArgs.target.id,
      element: spinnerArgs.target,
    };

    // keep a reference of the spinner instance for hiding and showing again
    this.spinnerInstances.push(newSpinner);
    return newSpinner;
  }

  /**
   * Show a loading spinner for the specified element
   * @param targetElement container element to house the loading spinner
   */
  public showSpinner(targetElement: HTMLElement) {
    let spinnerInstance = this.getSpinnerInstance(targetElement?.id);

    if (!targetElement?.id || !!spinnerInstance?.activeTimeout) {
      // param undefined or spinner already active
      this.logger.debug('showSpinner: targetElement undefined or no id, or spinner already active');
      return;
    }

    if (!spinnerInstance) {
      spinnerInstance = this.createSpinner({ target: targetElement });
    }

    showSFSpinner(targetElement);

    spinnerInstance.activeTimeout = setTimeout(
      () => this.loadingSpinnerTimeout(targetElement),
      this.configService.default.loadingSpinnerTimeout
    );
  }

  /**
   * If the spinner was not hidden before the timeout ended, hide spinner and notify user something may have gone wrong
   * @param targetElement element to hide.
   */
  private loadingSpinnerTimeout(targetElement: HTMLElement) {
    this.hideSpinner(targetElement?.id);
    this.logger.warn('Loading spinner timed out, no loading complete message received from ' + targetElement.id + ' before the timeout');

    // TODO confirm if this toast is required, if so update content. Toast v popup was to notify user vs forcing interaction with popup
    // May also want to handle this in the launcher and close the launched app instead
    this.toastService.showToast(
      this.resources.localisedStrings.loadingTimeoutTitle + ' ' + this.resources.getString(targetElement.id + 'Title'),
      this.resources.localisedStrings.loadingTimeoutContent
    );
  }

  /**
   * Hide the spinner for the specified element
   * @param id Application/feature name where the spinner is shown
   */
  public hideSpinner(id: string) {
    const spinnerInstance = this.getSpinnerInstance(id);
    if (!spinnerInstance?.activeTimeout) {
      this.logger.debug('hideSpinner: no id param, or spinner instance does not exist or is not active ' + id);
      return;
    }
    hideSFSpinner(spinnerInstance.element);
    clearTimeout(spinnerInstance.activeTimeout);
    spinnerInstance.activeTimeout = null;
  }

  /**
   * Hide and remove reference to all created loading spinners
   */
  public destroyAllSpinners(): void {
    this.spinnerInstances.forEach((spinner) => this.hideSpinner(spinner.id));
    this.spinnerInstances = [];
  }

  /**
   * Remove reference to a loading spinner instance
   * (call in ondestroy of parent application)
   * @param id Application/feature name where the spinner is shown
   */
  public destroySpinner(id: string) {
    const index = this.spinnerInstances.findIndex((s) => s.id === id);
    if (index > -1) {
      this.hideSpinner(id);
      this.spinnerInstances.splice(index, 1);
    }
  }
}
