import { Directive, OnInit, ElementRef, Input } from '@angular/core';
import { FeatureFlagService } from './feature-flag.service';

@Directive({
  selector: '[appRemoveIfFeatureOff]',
})
export class RemoveIfFeatureOffDirective implements OnInit {
  @Input('appRemoveIfFeatureOff') featureName: string;

  constructor(private el: ElementRef,
    private featureFlagService: FeatureFlagService) { }

  ngOnInit(): void {
    if (this.featureFlagService.featureOff(this.featureName)) {
      this.el.nativeElement.parentNode.removeChild(this.el.nativeElement);
    }
  }

}
