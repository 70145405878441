import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { ResourceKeys } from './resourceKeys';
import { CookieService } from '../cookie/cookie.service';
import { ToastService } from '../toast/toast.service';
import { ConfigService } from '../config/config.service';
import { UserflowService } from '../userflow/userflow.service';
import { LoggerService } from '../logger/logger.service';
import { L10n } from "@syncfusion/ej2-base";

// set strings for SF components
L10n.load({
  "en-US": {
    kanban: {
      // text displayed in an empty column, default is 'no cards to display'
      noCard: ""
    },
    dialog: {
      // tooltip for close button, default is 'Close'
      close: ""
    }
  }
});

const defaultLocale = 'en';
const supportedLocales = { 'en': 'English', 'de': 'Deutsch', 'fr': 'Français' };
// Other apps (Contribute etc) use LCID (Locale ID) instead of two-letter code
// https://docs.microsoft.com/en-us/openspecs/office_standards/ms-oe376/6c085406-a698-4e12-9d4d-c3b0ee3dbc4a
const localeToLcid = { en: 1033, de: 1031, fr: 1036 };
const lcidToLocale = { 1033: 'en', 1031: 'de', 1036: 'fr' };

enum ErrorMessages {
  // TODO - add all languages for hardcoded errors?
  ErrorTitle = 'Error encountered in application',
  NoResourcesAvailableCriticalError = 'Unable to load application resources',
  ChosenLanguageUnavailableWarning = 'Unable to load application resources for your chosen language, you can continue with English or reload to try again. <br/>Chosen language: ',
}

@Injectable({
  providedIn: 'root',
})
export class ResourceService {
  public localisedStrings: ResourceKeys;
  private http: HttpClient;
  private currentLocale: string;

  constructor(
    httpHandler: HttpBackend,
    private configService: ConfigService,
    private cookieService: CookieService,
    private toastService: ToastService,
    private userflowService: UserflowService,
    private logger: LoggerService
  ) {
    this.http = new HttpClient(httpHandler);
  }

  /**
   * Load resources to be used within the application
   */
  public async loadResources(): Promise<void> {
    // First check if there is a valid uilcid cookie
    const lcid = this.cookieService.getCookie(CookieService.keys.uilcid);
    if (lcid !== '' && lcidToLocale[lcid]) {
      this.currentLocale = lcidToLocale[lcid];
    }

    // validate if locale is defined and supported, change to default if not.
    if (!this.currentLocale || this.currentLocale === '' || !supportedLocales[this.currentLocale]) {
      this.currentLocale = defaultLocale;
    }

    // store selected locale for all applications
    this.setLocale(this.currentLocale);

    await this.loadResourcesForLocale(this.currentLocale);
  }

  /**
   * Load string resources for a specific locale
   * @param locale valid portal locale
   */
  private async loadResourcesForLocale(locale) {
    if (!supportedLocales[locale]) {
      // locale not supported or undefined, so set to default
      locale = defaultLocale;
    }

    let data;
    let errorMessage;

    try {
      data = await this.http
        .get<ResourceKeys>(
          `assets/resources/${locale}-resourceStrings.json`,
          { headers: { 'cache-control': 'no-cache' } }
        ).toPromise();
    } catch (e) {
      errorMessage = ErrorMessages.ChosenLanguageUnavailableWarning + supportedLocales[locale];
    }

    if (!data && locale !== defaultLocale) {
      // Unable to load resources, try instead to load default english resources
      try {
        data = await this.http
          .get<ResourceKeys>(
            `assets/resources/${defaultLocale}-resourceStrings.json`,
            { headers: { 'cache-control': 'no-cache' } })
          .toPromise();
      } catch (e) {
        errorMessage = ErrorMessages.NoResourcesAvailableCriticalError;
      }

      // default english resources loaded now
      if (!!data) {
        if (!this.logger) {
          // eslint-disable-next-line no-console
          console.warn(errorMessage);
        } else {
          this.logger.warn(errorMessage);
        }
        this.toastService.showToast('Warning', errorMessage, true);
      }
    }

    // no resources able to be loaded
    if (!data) {
      // Critical error, application unable to be loaded
      this.toastService.showToast(ErrorMessages.ErrorTitle, ErrorMessages.NoResourcesAvailableCriticalError);
      throw new Error(ErrorMessages.NoResourcesAvailableCriticalError);
    }
    // set localised resource strings for use within the application
    this.localisedStrings = data;
  }

  /**
   * Handle changing locale
   * @param newLocale locale to change to
   * @returns undefined if not supported or already set as current locale
   */
  public async changeLocale(newLocale: string) {
    newLocale = newLocale.toLowerCase();
    if (newLocale === this.currentLocale) return;
    if (!supportedLocales[newLocale]) return;
    this.currentLocale = newLocale;
    // store selected locale for all applications
    this.setLocale(newLocale);
    this.userflowService.setLocale(localeToLcid[newLocale]);

    this.loadResourcesForLocale(newLocale);
  }

  /**
   * Update locale stored for portal and other applications eg contribute
   * @param locale locale to store
   */
  private setLocale(locale) {
    // delete all existing uilcid cookies
    this.cookieService.deleteCookie(CookieService.keys.uilcid);
    // Language cookie expiry
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + this.configService.default.localeCookieExpiryDays);
    // Set language code for other applications (Contribute etc)
    this.cookieService.setCookie(CookieService.keys.uilcid, localeToLcid[locale], expiryDate);
  }

  /**
   * Re-save language cookie with updated expiry
   */
  public refreshLocaleCookie() {
    this.setLocale(this.currentLocale);
  }

  /**
   * Get the currently selected locale for portal
   * @returns currently selected locale
   */
  public getLocale(): string {
    return this.currentLocale;
  }

  /**
   * Get the currently selected locale for portal as an LCID code
   * @returns currently selected LCID
   */
  public getLocaleLcid(): string {
    return localeToLcid[this.currentLocale];
  }

  /**
   * Get localised resource string by key
   * @param key string identifier
   * @returns localised string
   */
  public getString(key: string): string {
    return this.localisedStrings[key] ? this.localisedStrings[key] : key;
  }
}
