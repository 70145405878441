import { ConfigService } from 'src/app/services/config/config.service';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { AuthorDialogComponent } from 'src/app/components/dialogs/author-dialog/author-dialog.component';
import { FeatureService } from 'src/app/services/feature/feature.service';
import { ResourceService } from 'src/app/services/resource/resource.service';
import {
  MenuPosition,
  FeatureItemModel,
} from '../../models/feature-item.model';
import { LauncherService } from 'src/app/services/launcher/launcher.service';
import { RecentBookService } from 'src/app/services/recent-book/recent-book.service';
import { AutologoutDialogComponent } from '../dialogs/autologout-dialog/autologout-dialog.component';

@Component({
  selector: 'app-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
})
export class SidePanelComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('sidebar', { static: false }) sidebar: SidebarComponent;
  @ViewChild('authorDialog') authorDialog: AuthorDialogComponent;
  @ViewChild('logoutDialog') logoutDialog: AutologoutDialogComponent;

  private checkTokenInterval = null;

  private authorDialogSub: Subscription;

  public sidebarSettings = {
    isOpen: true,
    enableDock: true,
    width: '220',
    dockSize: '74px',
    type: 'Push',
    target: '.main',
    enablePersistence: true,
    enableGestures: false,
  };

  public mainMenuItems: FeatureItemModel[];
  public secondaryMenuItems: FeatureItemModel[];

  constructor(
    private router: Router,
    public resources: ResourceService,
    private featureService: FeatureService,
    private authService: AuthenticationService,
    private launcherService: LauncherService,
    private recentBookService: RecentBookService,
    private configService: ConfigService
  ) { }

  ngOnInit() {
    this.mainMenuItems = this.featureService.features?.filter(
      (item) =>
        item.menu === MenuPosition.top &&
        this.authService.hasPermission(item.featureName)
    );
    this.secondaryMenuItems = this.featureService.features?.filter(
      (item) =>
        item.menu === MenuPosition.bottom &&
        this.authService.hasPermission(item.featureName)
    );
    this.checkShouldShowRecentBook();

    this.checkTokenInterval = setInterval(async () => {
      const authenticated = await this.authService.isAuthenticated();
      if (authenticated) {
        const active = await this.authService.checkToken();
        if (!active) {
          this.logoutDialog.showDialog();
        }
      }
    }, this.configService.default.autoLogoutPollInterval);

  }

  checkShouldShowRecentBook() {
    const recentBook = this.recentBookService.getRecentBook();
    if (recentBook?.bookId) {
      this.featureService.addRecentBook(recentBook.bookName);
      this.featureService.updateRouteParams('recentbook', '/' + recentBook.bookId, (recentBook?.bookName ? { name: recentBook.bookName } : ''));
    }
  }

  ngAfterViewInit() {
    this.authorDialogSub = this.authorDialog
      .getNotifyListener()
      .subscribe((data) => this.launchAuthor(data.type));
  }

  ngOnDestroy() {
    this.authorDialogSub?.unsubscribe();

    if (this.checkTokenInterval) {
      clearInterval(this.checkTokenInterval);
      this.checkTokenInterval = null;
    }
    this.featureService.resetRecentBook();
    this.featureService.resetAllRoutes();
  }

  toggleBtn() {
    this.sidebar.toggle();
  }

  isOpen() {
    if (!this.sidebar) {
      // if sidebar hasnt loaded yet default to true. Defaulting to false throws NG0100 error
      return true;
    } else {
      return this.sidebar.isOpen;
    }
  }

  // Menu item has custom action
  customAction(action: string) {
    switch (action) {
    case 'author':
      if (this.launcherService.appHasLaunched('author')) {
        this.launchAuthor('browser');
      } else {
        this.authorDialog.showDialog();
      }
      break;
    case 'logout':
      this.authService.logout();
      break;
    }
  }

  /**
   * Triggered by the authorLauncher when the user clicks to launch Author
   * @param type The type/method of launching AUthor (browser or rdp)
   */
  launchAuthor(type: string) {
    if (type === 'browser') this.router.navigate(['/Author']);
  }

  /**
   * Determine if the passed in route is currently active
   * @param featureRoute Route to check
   * @returns True if currently active
   */
  isActiveRoute(featureRoute: string): boolean {
    // not able to use routerLinkActive for all menu items, this provides styling to active route options
    return this.router.url.startsWith(featureRoute);
  }

  /**
   * Determine if the menu item should have a tooltip
   * @param featureName Name of the feature to check
   * @returns True if tooltip should be displayed
   */
  hasToolTip(featureName: string): boolean {
    if (!this.isOpen()) {
      // side panel is collapsed, always show tooltip
      return true;
    } else {
      // side panel is expanded, only show tooltip in these cases

      // get reference to the list item
      const menuLIEl = document.getElementById(featureName);
      // try and get span reference
      const span: HTMLSpanElement = menuLIEl?.firstChild?.lastChild as HTMLSpanElement;
      if (span?.clientWidth && span?.clientWidth >= 150) {
        // label is too long so an ellipses is shown, show the tooltip
        return true;
      }
    }
    // otherwise dont show tooltip
    return false;
  }
}
