import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { APIResponse } from 'src/app/models/api-response.model';
import { AuthResponse } from 'src/app/models/auth-response.model';
import { LibraryModel } from 'src/app/models/library.model';
import { User } from 'src/app/models/user.model';
import { ConfigService } from '../config/config.service';
import { CookieService } from '../cookie/cookie.service';
import { HttpService } from '../http/http.service';
import { LoggerService } from '../logger/logger.service';
import { ResourceService } from '../resource/resource.service';
import { UserflowService } from '../userflow/userflow.service';
import { UtilService } from '../util/util.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  /**
   * The currently login in user
   */
  private user: User = new User();

  /**
   * The secure token (once authenticated)
   */
  secureToken: string = null;

  public pendingLibrarySelection: boolean = false;

  /**
   * The route to redirect to after logging in
   */
  public redirectUrl: string = '';

  // TODO set this flag somewhere <- on init?
  public ssoDomain: string = null;

  /**
   * RXJS observable triggered when logout occurs. @see getLogoutListener
   */
  private logoutEvent = new Subject<{}>();

  public passwordUpdated = false;

  constructor(
    private router: Router,
    private httpService: HttpService,
    private resources: ResourceService,
    private cookieService: CookieService,
    private configService: ConfigService,
    private userflowService: UserflowService,
    private utils: UtilService,
    private logger: LoggerService
  ) { }

  /**
   * Called at application load to initialise session. Blocking.
   * Checks to see if token cookie exists, and validates the token.
   */
  async init(): Promise<void> {
    // Attempt to refresh the token
    if (await this.refreshToken()) {
      // User is authenticated

      // Load the user details
      await this.loadUser();

      // Save current language setting
      this.resources.refreshLocaleCookie();
      this.httpService.post('v1/user/language', { Lcid: this.resources.getLocaleLcid() });

      // load the currently selected library
      this.loadLibraryAlias();

      // Pass user details to userflow
      const library = this.getLoadedLibrary();
      this.userflowService.identify(this.user, this.resources.getLocaleLcid(), library);
    }

    // check if url is for an sso user, and set domain for application use
    await this.setSSODomain();
  }

  /**
   * Check if URL is for SSO user, and set SSO domain
   */
  private async setSSODomain(): Promise<void> {
    const domain = this.utils.window().location.hostname;
    const baseUrl = this.configService.baseUrl.substring(this.configService.baseUrl.indexOf('//') + 2);

    let ssoDomain = '';
    if (!(domain.indexOf('.') < 0 || baseUrl.indexOf('.') < 0)
      && baseUrl.split('.')[0] !== domain.split('.')[0]) {
      ssoDomain = domain.split('.')[0];
    }

    if (!!ssoDomain) {
      // accessing on a subdomain, check if this is a valid SSO url
      const apiResponse: APIResponse = await this.httpService.get(
        'v1/authentication/sso/validate/' + encodeURIComponent(ssoDomain)
      );
      if (!!apiResponse.Success) {
        // valid sso url, so set for application use
        this.ssoDomain = ssoDomain;
      }
    }
  }

  /**
   * Store specified url for redirecting back to after sso authentication
   * @param url original url the user was attempting to navigate to
   */
  public setSSORedirectUrl(url: string): void {
    const expiryDate = new Date();
    expiryDate.setSeconds(expiryDate.getSeconds() + this.configService.default.redirectCookieExpirySeconds);
    this.cookieService.setCookie(CookieService.keys.ssoRedirectUrl, url, expiryDate);
  }

  /**
   * Get sso redirect url from cookie and set for redirecting. Then clear the cookie so this method only returns value once.
   * @return redirect url from cookie, or empty string if not found/deleted already
   */
  public getSSORedirectUrl(): string {
    const ssoRedirectUrl = this.cookieService.getCookie(CookieService.keys.ssoRedirectUrl);
    // If this is an SSO request, the user is authenticated, and the redirect cookie is set, then set for redirection within portal
    if (!!ssoRedirectUrl && !!this.ssoDomain && this.isAuthenticated()) {
      this.redirectUrl = ssoRedirectUrl;
      this.cookieService.deleteCookie(CookieService.keys.ssoRedirectUrl);
    }
    return ssoRedirectUrl;
  }

  /**
   * clear the redirect url after refirecting
   */
  public clearRedirectUrl() {
    this.redirectUrl = '';
  }

  /**
   * Check if current user is authenticated and we have their details.
   * @returns True if authenticated, false otherwise
   */
  isAuthenticated(): boolean {
    return !!this.secureToken && this.user?.hasBasicDetails;
  }

  /**
   * After the user has authenticated, set up the remaining data needed for using the portal application
   * @param tokenString authenticated string
   * @param libraryAlias id of the library the user has loaded
   * @returns success:true or success:false with message
   */
  private async setAuthenticatedUserData(tokenString, libraryAlias): Promise<AuthResponse> {
    // Store token
    this.secureToken = tokenString;

    // Set the selected library as currently loaded library
    this.storeLibraryAlias(libraryAlias);

    // Load the current user details
    const loadUserResponse = await this.loadUser();
    if (!loadUserResponse.success) {
      // return error response from load user
      return loadUserResponse;
    }

    // Save current language setting
    this.resources.refreshLocaleCookie();
    this.httpService.post('v1/user/language', { Lcid: this.resources.getLocaleLcid() });

    // No need to remember password any more (temporarily stored between auth and library selection steps)
    this.user.password = '';
    this.pendingLibrarySelection = false;

    // Pass user details to userflow
    const library = this.getLoadedLibrary();
    this.userflowService.identify(this.user, this.resources.getLocaleLcid(), library);

    // Redirect to final destination
    this.router.navigate(['/Redirect' + this.redirectUrl]);

    return {
      success: true,
    };
  }

  /**
   * Try and log in a user with email and password
   * If the user has only one library a secure token is stored and login is completed.
   *
   * If the user has multiple libraries, a list of these libraries is returned and the secure token is not set.
   * If the user does not exist, null is returned.
   *
   * Note that if a user has multiple libraries this does NOT authenticate the user.
   * This verifies that a user exists only, and which libraies they have access to.
   * A second step (@see login) properly authenticates the user against a specific library.
   *
   * @param email User email address
   * @param password The password
   * @returns success:true or success:false with message
   */
  async login(
    email: string,
    password: string
  ): Promise<AuthResponse> {
    // Provide params in body. Note PascalCase
    const requestBody = {
      Email: email,
      Password: password,
      LibraryAlias: '',
    };
    // Make the call to cloud login
    // 1) If the details are not correct, validation will fail
    // 2) If the details are correct and the user has more than one library, a list of libraries are returned (and no token)
    // 3) If the details are correct and the user only has one library, they are authenticated and a token is returned
    const apiResponse: APIResponse = await this.httpService.post(
      'v1/authentication/cloudlogin',
      requestBody
    );

    // Success
    if (!!apiResponse.Success) {
      // Populate user
      this.user.email = email;

      // Map returned libraries to correct format
      this.user.libraries = apiResponse.Data?.LibraryList.map((element) => {
        return {
          id: element.LibraryAlias,
          name: element.LibraryName,
          default: !!element.DefaultLibrary,
        };
      });

      if (!apiResponse.Data?.TokenString) {
        // validated only. Not fully authenticated yet
        this.pendingLibrarySelection = true;

        this.secureToken = null;
        this.user.password = password; // Need to remember it for login

        // Return success
        return {
          success: true,
        };
      }

      // Authenticated, set up final user details
      return await this.setAuthenticatedUserData(apiResponse.Data.TokenString, this.user.libraries[0].id);
    }

    // Failed authentication
    let errorStr = this.resources.getString(apiResponse.Reason);
    if (errorStr === apiResponse.Reason) errorStr = this.resources.getString('unknownLoginError');
    return {
      success: false,
      message: errorStr,
    };
  }

  /**
   * Login with email and password (stored) and library alias
   * @param libraryAlias The alias of the library to log in with
   * @returns success:true or success:false with message
   */
  public async loginWithLibrary(
    libraryAlias: string
  ): Promise<AuthResponse> {
    // Provide params in body. Note PascalCase
    const requestBody = {
      Email: this.user.email,
      Password: this.user.password,
      LibraryAlias: libraryAlias,
    };

    // Make the call to cloud login
    // 1) If the details are not correct, authentication will fail
    // 2) If the details are correct and the user has more than one library, a list of libraries are returned (and no token)
    // 3) If the details are correct and the user only has one library, they are authenticated and a token is returned
    const apiResponse: APIResponse = await this.httpService.post(
      'v1/authentication/cloudlogin',
      requestBody
    );

    // Response is successful
    if (!!apiResponse.Success) {

      // No token
      if (!apiResponse.Data?.TokenString || apiResponse.Data.TokenString === '') {
        // Still not authenticated for some reason
        return {
          success: false,
          message: this.resources.localisedStrings.unknownLoginError,
        };
      }

      return await this.setAuthenticatedUserData(apiResponse.Data.TokenString, libraryAlias);
    }

    // Error
    let errorStr = this.resources.getString(apiResponse.Reason);
    if (errorStr === apiResponse.Reason) errorStr = this.resources.getString('unknownLoginError');
    return {
      success: false,
      message: errorStr,
    };
  }

  /**
   * Change to a new library
   * IMPORTANT: If not doing this as part of login, close all applications and remove all
   * library-specific details on successful change or there will be trouble!
   * Also if not happening as part of login, set authService.redirectUrl to the route
   * that the user should go to once the library has changed because the final step of
   * changing the library will trigger /Redirect to the redirectUrl.
   * @param libraryAlias The alias of the library to change to
   * @returns success:true or success:false with message
   */
  public async selectLibrary(
    libraryAlias: string
  ): Promise<AuthResponse> {
    // Make call to change the library
    const apiResponse: APIResponse = await this.httpService.get(
      'v1/library/select/' + libraryAlias
    );

    // Error
    if (!apiResponse.Success || !apiResponse.Data?.TokenString || !apiResponse.Data?.LibraryAlias) {
      return {
        success: false,
        message: this.resources.localisedStrings.unableToSelectLibraryError,
      };
    }

    // Set the user data and route to correct place in portal
    return await this.setAuthenticatedUserData(apiResponse.Data.TokenString, apiResponse.Data.LibraryAlias);
  }

  /**
   * Load the details of the current user (uses SecureToken to identify who)
   * @returns The currently authenticated user details
   */
  public async loadUser(): Promise<AuthResponse> {
    // Make api call to load details of current user
    const apiUserResponse: APIResponse = await this.httpService.get('v1/User/me');

    // Success
    if (!!apiUserResponse.Success) {
      // Copy user details from the response
      this.user.copyFromDtoUserAccount(apiUserResponse.Data);

      this.logger.setUserId(this.user.userId?.toString());

      // return ok
      return {
        success: true,
      };
    }
    // Failure
    else {
      // Clear the token
      this.secureToken = null;

      this.logger.setUserId('');

      // Return error
      let errorStr = this.resources.getString(apiUserResponse.Reason);
      if (errorStr === apiUserResponse.Reason) errorStr = this.resources.getString('unknownLoginError');
      return {
        success: false,
        message: errorStr,
      };
    }
  }

  public async loadRdpPassword(): Promise<{success: boolean, message?: string, rdpPassword?: string}> {
    // Retrieve the RDP password for current user
    const apiUserResponse: APIResponse = await this.httpService.get('v1/User/Rdp/Password/');
    // Success
    if (!!apiUserResponse.Success && !!apiUserResponse.Data) {
      // return ok
      return {
        success: true,
        rdpPassword: apiUserResponse.Data,
      };
    }
    // Failure
    else {
      // Return error
      return {
        success: false,
        // this message is not used in the UI
        message: this.resources.getString(apiUserResponse.Reason),
      };
    }
  }

  /**
   * Stub. Logout
   * TODO: Fix
   */
  public logout() {
    // Call API to invalidate token and release lisense(s)
    this.httpService.post('v1/authentication/logout', {});

    // Clear user and token
    this.user = new User();
    this.secureToken = null;
    this.redirectUrl = '';
    this.logger.setUserId('');
    this.deleteLibraryCookie();
    this.passwordUpdated = false;
    this.cookieService.deleteCookie(CookieService.keys.secureToken);

    // Log out of userflow
    this.userflowService.reset();

    // Notify (observable)
    this.logoutEvent.next({});

    // Route to login
    this.router.navigate(['Login']);
  }

  /**
   * Attempt to refresh the secure token
   * @returns true if successful
   */
  public async refreshToken(): Promise<boolean> {
    const token = this.cookieService.getCookie(CookieService.keys.secureToken);
    if (token !== '') {
      // Refresh token
      const apiResponse: APIResponse = await this.httpService.post('v1/authentication/token/refresh', {});
      // Success
      if (!!apiResponse.Success) {
        // Set the secure token
        this.secureToken = apiResponse.Data.TokenString;
        this.cookieService.setCookie(
          CookieService.keys.secureToken,
          apiResponse.Data.TokenString
        );
        return true;
      }
    }
    return false;
  }

  /**
   * Attempt to check the secure token
   * @returns true if successful
   */
  public async checkToken(): Promise<boolean> {
    const token = this.cookieService.getCookie(CookieService.keys.secureToken);
    if (token !== '') {
      // check token
      const apiResponse: APIResponse = await this.httpService.post('v1/authentication/token/check', {});
      // Success
      return apiResponse.Success;
    }
    // empty token
    return false;
  }

  /**
   * Set library as currently loaded, and store for the session
   * @param libraryAlias id of the library the user has loaded
   */
  private storeLibraryAlias(libraryAlias) {
    this.user.loadedLibraryAlias = libraryAlias;
    this.cookieService.setCookie(CookieService.keys.libraryAlias, libraryAlias);
  }

  /**
   * Attempt to load the library alias from the session cookie
   * or load if libraries list only has 1 library
   */
  private loadLibraryAlias() {
    // get from session cookie
    let storedLibrary = this.cookieService.getCookie(CookieService.keys.libraryAlias);

    if (!storedLibrary.trim()) {
      //  no library cookie
      if (this.user.libraries && this.user.libraries.length === 1) {
        // user libraries list only has 1 library, so set it here
        storedLibrary = this.user.libraries[0].id;
        // set session cookie
        this.cookieService.setCookie(CookieService.keys.libraryAlias, storedLibrary);
      }
    }
    if (!!storedLibrary.trim()) {
      // if found from cookie or list set here
      this.user.loadedLibraryAlias = storedLibrary;
      return;
    }
  }

  /**
   * Forgot password
   */
  public forgotPassword(email: string) {
    // Kick off the reset password process. We don't actually care if it succeeded or failed.
    // We can't make the user aware of this (even in the response of the API)
    // because we don't want to let on that the email address is for a valid user or not.
    const apiResponse = this.httpService.get(
      'v1/user/password/forgot/' + encodeURIComponent(email)
    );
  }

  /**
   * Reset the password after a previous forgot password call
   * @see forgotPassword
   * @param resetToken The reset token from the email
   * @param email The email address of the user
   * @param password The new password
   */
  public async resetPassword(resetToken: string, email: string, password: string) {
    const apiResponse = await this.httpService.post(
      'v1/user/password/reset/',
      {
        ResetToken: resetToken,
        Email: email,
        Password: password,
      }
    );
    if (!!apiResponse.Success) {
      // Success
      this.passwordUpdated = true;

      return {
        success: true,
      };
    }
    else {
      // Failure
      return {
        success: false,
        message: this.resources.getString(apiResponse.Reason),
      };
    }
  }

  /**
   * Listener that notifies every time logout is triggered
   * @returns Observable that notifies every time logout is triggered
   */
  public getLogoutListener(): Observable<{}> {
    return this.logoutEvent.asObservable();
  }

  /**
   * Check if the user have permissions to view a feature
   * @param permission The feature to check permissions on
   * @returns True if user has permissions, false otherwise
   */
  public hasPermission(permission: string): boolean {
    switch (permission) {
    // Always allow
    case 'home':
    case 'logout':
    case 'help':
    case 'settings':
    case 'profile':
      return true;

      // Permissions
    case 'library':
    case 'recentbook':
    case 'publishing':
      return this.user.isContributor || this.user.isContributorInherited;
    case 'reviews':
    case 'signoff':
      return this.user.isReviewer || this.user.isReviewerInherited;
    case 'translations':
      return this.user.isLocalizer || this.user.isLocalizerInherited;
    case 'files':
      return (
        this.user.isContributor ||
          this.user.isContributorInherited ||
          this.user.isAuthor ||
          this.user.isAuthorInherited
      );
    case 'author':
      return this.user.isAuthor || this.user.isAuthorInherited;
    }
    // Do not allow by default (least privilege-based permissions)
    return false;
  }

  /**
   * Delete the loaded library cookie
   */
  public deleteLibraryCookie(): void {
    this.cookieService.deleteCookie(CookieService.keys.libraryAlias);
  }

  /**
   * Get alias of the library that has been loaded for the authenticated user,
   * for multi-library users this is the library that they selected
   * @returns alias of loaded library, or undefined if not yet selected/available
   */
  public getLoadedLibraryAlias(): string {
    return this.getLoadedLibrary()?.id;
  }

  public getLoadedLibrary(): LibraryModel {
    if (!this.isAuthenticated()) {
      return undefined;
    }

    // library not loaded, try to get it from the cookie first
    if (!this.user.loadedLibraryAlias) {
      this.loadLibraryAlias();

      // still not loaded, undefined
      if (!this.user.loadedLibraryAlias) {
        return undefined;
      }
    }
    return this.user.libraries.find((lib) => lib.id === this.user.loadedLibraryAlias);
  }

  public getLibraries(): LibraryModel[] {
    if (!this.user.libraries || this.user.libraries.length === 0) return [];
    else return JSON.parse(JSON.stringify(this.user.libraries));
  }

  public getUserProperty(prop: string): any {
    return !!this.user[prop] ? this.user[prop] : undefined;
  }

  /**
   * Navigate to the sso login page
   * @param urlExtras anything to add to the end of the login page url
   * Example: &expired=1
   */
  public navToSSOLogin(urlExtras: string = '') {
    const domainName = '?domain=' + this.ssoDomain;
    const ssoLoginPath = '/SSOLogin.aspx';
    // eg. https: + // + testsso2.author-it.com + /SSOLogin.aspx + ?domain= + testsso2
    const ssoLoginUrl = this.utils.window().location.protocol + '//' + this.utils.window().location.hostname + ssoLoginPath + domainName + urlExtras;

    this.utils.window().location.href = ssoLoginUrl;
  }
}
