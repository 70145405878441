import { PublishingJob } from '../../models/publishing-job.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KanbanCardData } from 'src/app/models/kanban-card-data.model';
import { HttpService } from '../http/http.service';
import { APIResponse } from 'src/app/models/api-response.model';
import { ConfigService } from '../config/config.service';
import { UtilService } from '../util/util.service';

@Injectable({
  providedIn: 'root',
})
export class HomePageService {

  constructor(
    private httpService: HttpService,
    private httpClient: HttpClient,
    private configService: ConfigService,
    private utils: UtilService
  ) { }

  /**
   * Get all published jobs from the last 31 days for the current user
   * @returns published jobs
   */
  async getRecentPublishingJobs(): Promise<PublishingJob[]> {
    let fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - this.configService.default.homePageRecentItemsAge);
    const fromDateStr = this.utils.formatDate(fromDate, this.configService.default.apiDateTimeFormat);
    const queryString = '&FromDate=' + fromDateStr;

    const apiResponse: APIResponse = await this.httpService
      .get('v1/PublishingJobs/search?OnlyCurrentUser=1' + queryString);

    if (!!apiResponse.Success && !!apiResponse.Data) {
      // convert object headers in array to camel cased object array
      const pubJobsData: PublishingJob[] = this.utils.toCamel(apiResponse.Data);

      // sort most recent publishing jobs first
      const sortedPubJobs = pubJobsData.sort((a, b) => Date.parse(b.submittedTime) - Date.parse(a.submittedTime));

      // Distinct list, by book id and output format
      const disctinctPubJobs: PublishingJob[] = [];
      const map = new Map();
      for (const job of sortedPubJobs) {
        if (!map.has(job.bookId + ':' + job.format)) {
          map.set(job.bookId + ':' + job.format, true); // set any value to Map
          disctinctPubJobs.push(job);
        }
      }

      // limit results to 10
      return disctinctPubJobs.slice(0, 10);
    }
    return;
  }

  /**
   * Get static card details
   * @returns card details
   */
  async getOtherItems(): Promise<KanbanCardData[]> {
    // load static data from local file, change to use httpService if calling api
    const staticKanbanItems = await this.httpClient.get<KanbanCardData[]>('assets/static-kanban-data.json').toPromise();

    if (staticKanbanItems) {
      return this.utils.toCamel(staticKanbanItems);
    }
    return;
  }

}
