import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResourceService } from 'src/app/services/resource/resource.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  /**
   * Type of error to show, directly references title and content from resource strings
   * error[type]PageTitle, ie. error404PageTitle
   */
  public type: string = '404';

  constructor(
    public resources: ResourceService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const typeFromRoute = this.route?.snapshot?.paramMap?.get('type');

    // confirm resource strings exist for error type before assigning passed in type
    if (!!typeFromRoute
      && this.resources.getString(`error${typeFromRoute}PageTitle`) !== typeFromRoute
      && this.resources.getString(`error${typeFromRoute}PageContent`) !== typeFromRoute) {
      this.type = typeFromRoute;
    }
  }
}
