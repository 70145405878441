<div class="wrap">
  <div class="background-strip"></div>
  <ejs-dialog cssClass="dark large" #loginDialog [width]="dialogWidth" [height]="dialogHeight">
    <ng-template #content>
      <div class="logo"><img src='assets/img/ait-logo-full.svg' alt="Author-it logo" /></div>
      <div *ngIf="isLoginStep(1);" class="title">{{resources.localisedStrings.logInTitle}}</div>
      <div *ngIf="isLoginStep(2);" class="title">{{resources.localisedStrings.changeLibraryTitle}}</div>

      <ul class="messages" *ngIf="messages?.length">
        <li *ngFor="let message of messages">
          <span (click)="messageClick(message.controlName)" [class]="message.type">{{message.text}}</span>
        </li>
      </ul>
      <form [formGroup]="loginForm">

        <div *ngIf="isLoginStep(1);">
          <div class="control">
            <ejs-textbox [formControl]="email" name="email" autocomplete="username" [placeholder]='resources.localisedStrings.emailLabel' floatLabelType="Auto"></ejs-textbox>
          </div>
          <div class="control">
            <ejs-textbox [formControl]="password" name="password" type="password" autocomplete="current-password" [placeholder]='resources.localisedStrings.passwordLabel' floatLabelType="Auto"></ejs-textbox>
          </div>
          <!-- <div class="control">
            <ejs-checkbox [label]='resources.localisedStrings.rememberNextTimeLabel'></ejs-checkbox>
          </div> -->
          <div class="buttons">
            <button type="submit" [disabled]="loginForm.disabled" (click)="login()"
              ejs-button cssClass="e-primary" [ngClass]="loading ? 'loading': ''">
              <ng-template [ngIf]="!loginForm.disabled" [ngIfElse]="disabledButtonContent">
                {{resources.localisedStrings.continueLabel}}
              </ng-template>
            </button>
          </div>
        </div>

        <div *ngIf="isLoginStep(2);">
          <div class="control">
            <ejs-dropdownlist [formControl]="library" cssClass="dark large" name="library" [dataSource]="libraryData" [fields]="libraryDataFields"
              [placeholder]='resources.localisedStrings.libraryLabel' floatLabelType="Auto"></ejs-dropdownlist>
          </div>
          <div class="buttons">
            <button type="submit" [disabled]="loginForm.disabled" (click)="loginWithLibrary()"
              ejs-button cssClass="e-primary" [ngClass]="loading ? 'loading': ''">
              <ng-template [ngIf]="!loginForm.disabled" [ngIfElse]="disabledButtonContent">
                {{resources.localisedStrings.logInLabel}}
              </ng-template>
            </button>
          </div>
        </div>

        <!-- text and icon to display when login etc button is disabled -->
        <ng-template #disabledButtonContent>
          {{resources.localisedStrings.loadingLabel}}&nbsp;
          <app-icon iconName="loading" [spin]="true"></app-icon>
        </ng-template>

      </form>

      <div *ngIf="isLoginStep(1);">
        <div class="below-dialog">
          <a href="{{configService.newAccountUrl}}" target="_blank">{{resources.localisedStrings.dontHaveAnAccountLink}}</a><br />
          <a *ngIf="!authService.ssoDomain" (click)="forgotPassword()">{{resources.localisedStrings.forgotYourPasswordLink}}</a><br />
        </div>
      </div>

      <div *ngIf="isLoginStep(2) && !authService.ssoDomain">
        <div class="below-dialog">
          <a (click)="backToLogin()">{{resources.localisedStrings.goBackLink}}</a>
        </div>
      </div>

    </ng-template>
  </ejs-dialog>
  <div class="locales">
    <a *ngIf="resources.getLocale()!=='en'" (click)="changeLocale('en')">
      <img src="assets/img/en-16h.png" alt="" />English</a>
    <a *ngIf="resources.getLocale()!=='de'" (click)="changeLocale('de')">
      <img src="assets/img/de-16h.png" alt="" />Deutsch</a>
    <a *ngIf="resources.getLocale()!=='fr'" (click)="changeLocale('fr')">
      <img src="assets/img/fr-16h.png" alt="" />Français</a>
  </div>
</div>
