import { Injectable } from '@angular/core';
import { RecentBook } from 'src/app/models/recent-book.model';
import { AuthenticationService } from '../authentication/authentication.service';
import { HttpService } from '../http/http.service';
import { LoggerService } from '../logger/logger.service';
import { UtilService } from '../util/util.service';

const recentBookKey = 'portalRecentBook';

@Injectable({
  providedIn: 'root',
})
export class RecentBookService {

  constructor(
    private authService: AuthenticationService,
    private logger: LoggerService,
    private httpService: HttpService,
    private utils: UtilService
  ) { }

  public getRecentBook(): RecentBook {
    try {
      const storedRecentBook: string = localStorage.getItem(recentBookKey);
      if (!storedRecentBook) {
        return;
      }

      const recentBookString = this.utils.base64Decode(storedRecentBook);
      const recentBookObj = JSON.parse(recentBookString) as RecentBook;

      // check the stored book is for the current user and currently loaded library
      const library = this.authService.getLoadedLibraryAlias();
      const userId = this.authService.getUserProperty('userId') as string;
      if ((recentBookObj.library === library) && (recentBookObj.user ===  userId)) {
        return recentBookObj;
      }
    } catch (e) {
      this.logger.error('Internal error occurred while retrieving recent book info. ' + e.message);
    }
    return;
  }

  public storeRecentBook(bookId: string, bookName?: string): void {
    if (!bookId) {
      return;
    }

    try {
      const library = this.authService.getLoadedLibraryAlias();
      const userId = this.authService.getUserProperty('userId') as string;
      if (!library) {
        throw new Error('Library undefined: ' + library);
      }
      if (!userId) {
        throw new Error('user ID undefined: ' + userId);
      }
      const recentBook = JSON.stringify({
        user: userId,
        library: library,
        bookId: bookId as string,
        bookName: bookName || '',
      });
      const storeRecentBook = this.utils.base64Encode((recentBook));
      if (!recentBook) {
        // TODO, localised error messages
        this.logger.error('Error updating recent book for: ' + bookId);
        return;
      }
      localStorage.setItem(recentBookKey, storeRecentBook);
    } catch (e) {
      // TODO, localised error messages
      this.logger.error('Internal error occurred saving recently accessed book: ' + bookId + '. Error: ' + e.message);
      return;
    }
  }

  /**
   * Get title of book, look in LS otherwise call API
   * @param bookId Id of the book to search for
   * @returns title of book if found
   */
  public async getBookTitle(bookId: string): Promise<string> {
    if (!bookId || !Number.parseInt(bookId, 0)) {
      return;
    }

    const bookDetails = this.getRecentBook();
    if (bookDetails?.bookId === bookId && bookDetails?.bookName) {
      return bookDetails.bookName;
    }

    const apiResponse = await this.httpService.get('v1/book/' + bookId);
    if (!apiResponse.Success || !apiResponse?.Data?.Description) {
      this.logger.warn('Title of recent book not found, book Id: ' + bookId);
      return;
    }
    const bookName = apiResponse.Data.Description;

    return bookName;
  }
}
