import { Injectable } from '@angular/core';
import { Toast, ToastModel } from '@syncfusion/ej2-angular-notifications';
import { UtilService } from '../util/util.service';

interface ToastInfo {
  title: string;
  message: string;
}

// Full info https://www.syncfusion.com/kb/10340/how-to-use-toast-as-an-angular-service
@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private utils: UtilService) {}
  private toastInstance: Toast;
  private toastProperties: ToastModel = { showCloseButton: true, position: { X: 'Right', Y: 'Bottom' }, timeOut: 5000 };
  /**
   * Toast info to show after the application has loaded
   */
  private toastToShowAfterLoad: ToastInfo;

  /**
   * When toast isnt available, display an alert instead
   * @param title title of the alert
   * @param content body content of the alert
   */
  private fallBack(title: string, content: string) {
    this.utils.window().alert(title + '\n\nError: \n' + content);
  }

  /**
   * Show stored toast message, if one was stored prior to application load
   */
  public showStoredToastAfterAppLoad() {
    if (!!this.toastToShowAfterLoad) {
      this.showToast(this.toastToShowAfterLoad.title, this.toastToShowAfterLoad.message);
      this.toastToShowAfterLoad = undefined;
    }
  }

  /**
   * Add toast information to show after the application has loaded
   * @param title Toast title
   * @param message Toast message
   */
  public addToastToShowAfterLoad(title: string, message: string) {
    this.toastToShowAfterLoad = { title: title, message: message };
  }

  /**
   * Show a toast message to the user, defaults to alert if the application has not loaded
   * @param title Toast title
   * @param message Toast message
   */
  public showToast(title: string, message: string, waitForAppLoaded: boolean = false) {
    const toastElem = document.getElementById('ej2Toast');
    // null if application not loaded
    if (!toastElem) {
      if (waitForAppLoaded) {
        // application not loaded, want to wait until loaded to show error
        this.addToastToShowAfterLoad(title, message);
      } else {
        this.fallBack(title, message);
      }
      return;
    } else {
      const propertiesModel: ToastModel = { ...this.toastProperties, title: title, content: message };
      this.toastInstance = new Toast(propertiesModel, toastElem);
      this.toastInstance.show();
    }
  }

  /**
   * Hide toast
   */
  public hideToast() {
    if (this.toastInstance) {
      this.toastInstance.hide('All');
    }
  }
}
