
export interface FeatureItemModel {
  /**
   * Resource key for the feature title, so it will be shown in the correct language
   */
  titleRef: string;
  /**
   * Internal navigation route within portal
   */
  route: string;
  icon: string;
  featureName: string;
  menu: number;
  /**
   * optional application name to be logged
   */
  logName?: string;
  /**
   * Anything to concat directly onto the end of the provided route, currently used for dynamic child routes
   */
  routeExtras?: string;
  queryParams?: {};
  /**
   * Hide in the sidebar menu
   */
  hidden?: boolean;
  /**
   * Specify a target if its not just the feature name (ie. contribute is the messenger target for all applications loaded from contribute)
   */
  messengerTarget?: string;
  /**
   * Identifies a custom click action is used for the sidebar menu item
   */
  action?: string;
  /**
   * Url to load in a new browser tab, instead of the provided route in the sidebar menu
   */
  externalUrl?: boolean;
  alwaysReloadIframe?: boolean;
  /**
   * Supply a title that should be shown exactly as is with no translation applied, eg a book name
   */
  exactTitle?: string;

  /**
   * Show a control bar for iframes loaded in the workspace
   */
  showControlBar?: boolean;
  controlBarAllowReload?: boolean;
  controlBarAllowClose?: boolean;

  /**
   * Show a loading spinner when launching this feature
   */
  showloadingSpinner?: boolean;
}

export enum MenuPosition {
  top = 1,
  bottom = 2,
}
