import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { ReleaseStateModel } from 'src/app/models/release-state.model';
import { UtilService } from '../util/util.service';

/**
 * enum as per AuthorIT.API.Dto.Reviewer
 */
export enum ReviewType {
  Collaboration = 1,
  Approval = 2,
}

@Injectable({
  providedIn: 'root'
})
export class ReviewService {
  constructor(private httpService: HttpService, private utils: UtilService) { }

  public async getReview(reviewId: string): Promise<{success:boolean, data?: any, reason?: string}> {
    const result = await this.httpService.get(`v1/Review/${reviewId}`);

    // if responses not returned or unsuccessful
    if(!result?.Success || !result.Data) {
      return {success: false, reason: result.Reason};
    }

    const resultData = this.utils.toCamel(result.Data);
    return {success: true, data: resultData};
  }

  public async getResolvedBookToc(reviewId: string, reviewStatus: string): Promise<{success:boolean, data?: any, reason?: string}> {
    const result = await this.httpService.get(`v1/Review/ResolvedBookToc/${reviewId}/${reviewStatus}`);

    // if responses not returned or unsuccessful
    if(!result?.Success || !result.Data) {
      return {success: false, reason: result.Reason};
    }

    const resultData = this.utils.toCamel(result.Data);
    return {success: true, data: resultData};
  }

  public async getReleaseStates(): Promise<{success:boolean, data?: ReleaseStateModel[], reason?: string}> {
    const result = await this.httpService.get('v1/ReleaseState/GetAll');

    // if responses not returned or unsuccessful
    if(!result?.Success || !result.Data) {
      return {success: false, reason: result.Reason};
    }

    const resultData = this.utils.toCamel(result.Data);
    return {success: true, data: resultData};
  }

  public async completeReview(reviewId: string, reviewType: string): Promise<{success:boolean, data?: any, reason?: string}> {
    const result = await this.httpService.post('v1/Review/CompleteReview', { ReviewId: reviewId, ReviewType: reviewType });

    // if unsuccessful
    if(!result?.Success) {
      return {success: false, reason: result.Reason};
    }

    return {success: true};
  }
}
