import { Component, ViewChild } from '@angular/core';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { ConfigService } from 'src/app/services/config/config.service';
import { ResourceService } from 'src/app/services/resource/resource.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { DialogSettingsModel } from 'src/app/models/dialog-settings.model';

@Component({
  selector: 'app-autologout-dialog',
  templateUrl: './autologout-dialog.component.html',
  styleUrls: ['./autologout-dialog.component.scss'],
})
export class AutologoutDialogComponent {
  @ViewChild('autoLogoutDialog') dialog: DialogComponent;

  public dialogSettings: DialogSettingsModel = {
    width: '500px',
    height: 'auto',
    cssClass: 'dark',
    isModal: true,
    visible: false,
  };

  constructor(
    public configService: ConfigService,
    public resources: ResourceService,
    private authService: AuthenticationService
  ) {}

  hideDialog() {
    this.dialog.hide();
  }

  showDialog() {
    this.dialog.show();
  }

  loginAgain() {
    this.authService.logout();
    this.hideDialog();
  }
}
