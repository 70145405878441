import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { UtilService } from '../util/util.service';

const portalDomain = '.author-it.com';
@Injectable({
  providedIn: 'root',
})
export class CookieService {
  constructor(private configService: ConfigService, private utils: UtilService) { }

  /**
   * Keys for storing and retrieving cookie data
   */
  public static keys = {
    secureToken: 'SecureToken',
    libraryAlias: 'LibraryAlias',
    uilcid: 'uilcid',
    ssoRedirectUrl: 'SsoRedirectUrl',
  };

  public getCookie(name: string): string {
    let val = '';
    document.cookie.split(';').every((c) => {
      c = c.trim();
      if (c.indexOf(name + '=') === 0) {
        val = c.substring(name.length + 1);
        return false;
      }
      return true;
    });
    return val;
  }

  public setCookie(name: string, value: any, expiryDate?: Date): void {
    let cookieString = name + '=' + value + '; Path=/';
    if (this.utils.window().location.hostname.indexOf('author-it.com') >= 0) {
      cookieString += '; Domain=' + portalDomain;
    }
    if (expiryDate) {
      cookieString += '; Expires=' + expiryDate.toUTCString();
    }

    if (!!this.configService?.default?.secureCookieAttribute) {
      cookieString += '; Secure';
    }
    if (['strict', 'lax'].includes(this.configService?.default?.sameSiteCookieAttribute?.toLowerCase())) {
      cookieString += '; SameSite=' + this.configService.default.sameSiteCookieAttribute;
    }

    document.cookie = cookieString;
  }

  /**
   * Delete a cookie if set for portal and other applications
   * @param name key of cookie to delete
   * @param domain optional, if needing to specify exact domain to remove a cookie.
   */
  public deleteCookie(name: string) {
    // REQUIRES PATH AND DOMAIN
    if (this.getCookie(name)) {
      // set to date in the past to delete

      // old portal set cookies without a domain so must be deleted without a domain
      document.cookie = name + '=; expires=' + new Date(-1) + '; path=/;';
      // new portal specifies a domain so must be deleted with a domain
      document.cookie = name + '=; expires=' + new Date(-1) + '; path=/; domain=' + portalDomain;
    }
  }
}
