import { Injectable } from '@angular/core';
import { APIResponse } from 'src/app/models/api-response.model';
import { AuthResponse } from 'src/app/models/auth-response.model';
import { HttpService } from '../http/http.service';
import { ResourceService } from '../resource/resource.service';

@Injectable({
  providedIn: 'root'
})
export class RdpService {

  constructor(
    private httpService: HttpService,
    private resources: ResourceService,
  ) { }

  public async downloadRDPFile(): Promise<AuthResponse> {
    // Make the call to load RDP file for current user
    // TODO: Update with proper path and arguments
    const apiUserResponse: APIResponse = await this.httpService.get('v1/User/Rdp/File');
    // Success
    if (!!apiUserResponse.Success) {
      // Copy user details from the response
      this.downloadFile(apiUserResponse.Data);

      // return ok
      return {
        success: true,
      };
    }
    // Failure
    else {
      // Return error
      return {
        success: false,
        message: this.resources.getString(apiUserResponse.Reason),
      };
    }
  }

  downloadFile(data: any) {
    const blob = new Blob([data], { type: 'application/x-rdp' });
    const a = document.createElement('a');
    const objectUrl = URL.createObjectURL(blob);
    a.href = objectUrl;
    a.download = 'ait.gateway.author-it.com.rdp';
    a.click();
    URL.revokeObjectURL(objectUrl);
  }
}
