import { Component, OnDestroy, ViewChild } from '@angular/core';
import { TextBoxComponent } from '@syncfusion/ej2-angular-inputs';
import { Observable, Subject } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { ResourceService } from 'src/app/services/resource/resource.service';
import { RdpService } from 'src/app/services/rdp/rdp.service';
import { UtilService } from 'src/app/services/util/util.service';
import { FeatureService } from 'src/app/services/feature/feature.service';
import { SharedDialogComponent } from '../../shared/dialog/dialog.component';
import { DialogSettingsModel } from 'src/app/models/dialog-settings.model';

@Component({
  selector: 'app-author-dialog',
  templateUrl: './author-dialog.component.html',
  styleUrls: ['./author-dialog.component.scss'],
})
export class AuthorDialogComponent implements OnDestroy {
  /**
   * Reference to the dialog for repositioning on screen resize
   */
  @ViewChild('launchAuthorDialog') dialog: SharedDialogComponent;

  /**
   * Reference to the password textbox
   */
  @ViewChild('password') password: TextBoxComponent;

  /**
   * Password loaded from API
   */
  private rdpPassword: string = '';
  private rdpPasswordExpired: boolean = true;

  /**
   * Subject to annouce when user chooses to launch Author
   */
  private notify = new Subject<{ type: string }>();

  /**
   * List of current messages.
   * [
   *    { text:"The text", type:"type", controlName?:"name" }
   * ]
   * type can be error, warning or info (default is info)
   * controlName (optional) is the name of the control that the message relates to
   */
  messages = [];

  public dialogSettings: DialogSettingsModel = {
    width: '500px',
    height: 'auto',
    cssClass: "dark",
    isModal: true,
    visible: false,
  };

  constructor(
    public configService: ConfigService,
    private authService: AuthenticationService,
    public resources: ResourceService,
    private logger: LoggerService,
    private rdpService: RdpService,
    private utils: UtilService,
    private features: FeatureService
  ) { }

  /**
   * Copy passed in text to the clipboard (can move to generic util service)
   * @param textToCopy text to copy
   * @returns true if copied successfully
   */
  private copyToClipboard(textToCopy) {
    let success = false;
    const copyListener = (e: ClipboardEvent) => {
      try {
        const clipboard = e.clipboardData || this.utils.window()['clipboardData'];
        clipboard.setData('text', textToCopy);
        e.preventDefault();
        success = true;
      } catch (e) {
        success = false;
      }
    };
    document.addEventListener('copy', copyListener, false);
    document.execCommand('copy');
    document.removeEventListener('copy', copyListener, false);
    return success;
  }

  private async loadRdpPassword() {
    if(this.rdpPasswordExpired)  {
      // Load password from API
      const response = await this.authService.loadRdpPassword();

      if (!response?.success || !response?.rdpPassword) {
        return false;
      }

      this.rdpPassword = response.rdpPassword;
      this.rdpPasswordExpired = false;
      this.expireRdpPassword();
    }
    return true;
  }

  //Copy the password to clipboard
  async copyRdpPasswordToClipboard() {
    this.messages = [];

    if(!await this.loadRdpPassword()) {
      this.messages.push({
        text: this.resources.localisedStrings.loadingPasswordFailedLabel,
        type: 'error',
      });
      return;
    }

    const copySuccess = this.copyToClipboard(this.rdpPassword);
    if (!copySuccess) {
      this.messages.push({
        text: this.resources.localisedStrings.loadingPasswordFailedLabel,
        type: 'error',
      });
    } else {
      this.messages.push({
        text: this.resources.localisedStrings.passwordCopiedMessage,
        type: 'info',
      });
    }
  }

  /**
   * Set the retrieved RDP password as expired after x amount of time,
   * to ensure a new one is retrieved on call
   */
  private expireRdpPassword() {
    setTimeout(() => {
      this.rdpPasswordExpired = true;
    }, this.configService.default.rdpPasswordApiCallTimeout);
  }

  openBrowser() {
    // Sidebar has subscribed to this message
    this.notify.next({ type: 'browser' });
    this.dialog.hide();
  }

  hideDialog() {
    this.dialog.hide();
  }

  showDialog() {
    this.messages = [];
    this.dialog.show();
    setTimeout(() => {
      this.rdpPassword = '';
    }, this.configService.default.rdpPasswordApiCallTimeout);
  }

  /**
   * Listener that notifies every time a message is received for the Portal (where target is portal)
   * @returns Observable that notifies every time there is a new message received for the Portal
   */
  public getNotifyListener(): Observable<{ type: string }> {
    return this.notify.asObservable();
  }

  private async downloadRDPFile() {
    this.messages = [];

    const feature = this.features.getFeatureByFeatureName('author');
    this.logger.info(`${feature.featureName}-rdp accessed`, { action: 'application-open', actionType: 'access', details: { 'application-accessed': `${feature.featureName}-rdp` } });

    return await this.rdpService.downloadRDPFile();
  }

  /**
   * download the rdp file & copy the password to clipboard
   */
  async openDesktop() {
    this.messages = [];

    if(!(await this.downloadRDPFile())?.success) {
      this.messages.push({
        text: this.resources.localisedStrings.rdpFileDownloadedError,
        type: 'error',
      });
      return;
    }
    this.messages.push({
      text: this.resources.localisedStrings.rdpFileDownloadedMessage,
      type: 'info',
    });

    if(!await this.loadRdpPassword()) {
      this.messages.push({
        text: this.resources.localisedStrings.loadingPasswordFailedLabel,
        type: 'error',
      });
      return;
    }
    if (!this.copyToClipboard(this.rdpPassword)) {
      this.messages.push({
        text: this.resources.localisedStrings.loadingPasswordFailedLabel,
        type: 'error',
      });
    }
  }

  ngOnDestroy(): void {
    this.rdpPassword = '';
  }
}
