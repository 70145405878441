<div class="controlBar" [hidden]="hideControlBar">
  <div class="header">
    <ng-template [ngIf]="headerParts && headerParts.length > 0" *ngFor="let header of headerParts; last as isLast">
      <p [ngClass]="isLast ? 'mainTitle' : ''">
        <ng-template [ngIf]="header.direct" [ngIfElse]="block2">
          {{featureService.getFeatureByFeatureName(header.title).exactTitle}}
        </ng-template>

        <ng-template #block2>
          <ng-template [ngIf]="header.exact" [ngIfElse]="translatedText">
            {{header.title}}
          </ng-template>

          <ng-template #translatedText>
            {{resources.localisedStrings[header.title]}}
          </ng-template>
        </ng-template>
      </p>

      <p *ngIf="!isLast">
        /
      </p>
    </ng-template>
    <div class="actions">
      <a *ngIf="activeFeature?.controlBarAllowReload" class="action" (click)="onReloadClick()">
        <app-icon iconName="refresh" fixedWidth size="lg"></app-icon>
      </a>
      <a *ngIf="activeFeature?.controlBarAllowClose" class="action" (click)="onCloseClick()">
        <app-icon iconName="closeControlBar" fixedWidth size="lg"></app-icon>
      </a>
    </div>
  </div>
</div>